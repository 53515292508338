import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { format, parseISO, isValid, addDays, subDays } from 'date-fns';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { Calendar } from 'lucide-react';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon} from '@heroicons/react/24/outline';
import axios from 'axios';

// Using a cache to store patient names and billing info across renders
const patientCache = {
  names: {},
  billingInfo: {}
};

const EventsTable = ({ events, status }) => {
    const navigate = useNavigate();
    const [patientNames, setPatientNames] = useState(patientCache.names || {});
    const [patientBillingInfo, setPatientBillingInfo] = useState(patientCache.billingInfo || {});
    const [loadingNames, setLoadingNames] = useState(false);
    
    // Only process events that need to be displayed
    const filteredEvents = useMemo(() => {
        return events.filter(event => event.patient_id !== '0' && event.patient_id !== 0);
    }, [events]);

    // Memoize unique patient IDs to fetch
    const patientIdsToFetch = useMemo(() => {
        return [...new Set(
            filteredEvents
                .map(event => event.patient_id)
                .filter(id => id && id !== 'null' && !patientNames[id])
        )];
    }, [filteredEvents, patientNames]);

    // Memoize unique patient IDs to fetch billing info for
    const billingIdsToFetch = useMemo(() => {
        return [...new Set(
            filteredEvents
                .map(event => event.patient_id)
                .filter(id => id && id !== 'null' && !patientBillingInfo[id])
        )];
    }, [filteredEvents, patientBillingInfo]);

    const batchFetchPatientNames = useCallback(async (patientIds) => {
        if (!patientIds || patientIds.length === 0) return {};
        
        try {
            // Make a single API call with all patient IDs
            const response = await axios.post(
                'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/batch_patient_names',
                { patientIds },  // Send all IDs in one request
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            
            if (response.data && response.data.body) {
                const patientData = JSON.parse(response.data.body);
                return patientData; // Should be a map of ID -> name
            }
            
            return {};
        } catch (error) {
            console.error('Error batch fetching patient names:', error);
            return {};
        }
    }, []);

    const individualFetchPatientNames = useCallback(async (patientIds) => {
        const uniqueIds = [...new Set(patientIds)];
        console.log(`Fetching names for ${uniqueIds.length} patients individually`);
        
        const namesMap = {};
        
        // Create a cache for failed lookups to avoid repeating them
        const failedLookups = new Set();
        
        // Create batches of 5 requests to run in parallel
        const batchSize = 5;
        for (let i = 0; i < uniqueIds.length; i += batchSize) {
            const batch = uniqueIds.slice(i, i + batchSize);
            
            // Create an array of promises for the current batch
            const batchPromises = batch.map(async (patientId) => {
                if (failedLookups.has(patientId)) return;
                
                try {
                    const response = await axios.get(
                        'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/find_patient',
                        {
                            headers: {
                                PatientID: patientId,
                                'Content-Type': 'application/json'
                            }
                        }
                    );
                    
                    if (response.data && response.data.body) {
                        const parsedBody = JSON.parse(response.data.body);
                        
                        if (Array.isArray(parsedBody) && parsedBody.length > 0) {
                            const patientData = parsedBody[0];
                            if (patientData.FirstName && patientData.LastName) {
                                namesMap[patientId] = `${patientData.LastName}, ${patientData.FirstName}`;
                            } else {
                                failedLookups.add(patientId);
                            }
                        } else {
                            failedLookups.add(patientId);
                        }
                    } else {
                        failedLookups.add(patientId);
                    }
                } catch (error) {
                    failedLookups.add(patientId);
                    console.error('Error fetching patient info:', error);
                }
            });
            
            // Wait for the current batch to complete before moving to the next one
            await Promise.all(batchPromises);
        }
        
        return namesMap;
    }, []);

    const batchFetchBillingInfo = useCallback(async (patientIds) => {
        if (!patientIds || patientIds.length === 0) return {};
        
        const billingInfoMap = {};
        
        // Create batches of 5 requests to run in parallel
        const batchSize = 5;
        for (let i = 0; i < patientIds.length; i += batchSize) {
            const batch = patientIds.slice(i, i + batchSize);
            
            // Create an array of promises for the current batch
            const batchPromises = batch.map(async (patientId) => {
                try {
                    const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/find_patient_billing_info', {
                        headers: {
                            'PatientID': patientId
                        }
                    });
                    
                    const data = await response.json();
                    if (data.statusCode === 200 && data.body) {
                        const parsedBody = JSON.parse(data.body);
                        if (Array.isArray(parsedBody) && parsedBody.length > 0) {
                            billingInfoMap[patientId] = parsedBody[0];
                        }
                    }
                } catch (error) {
                    console.error(`Error fetching billing info for patient ${patientId}:`, error);
                }
            });
            
            // Wait for the current batch to complete before moving to the next one
            await Promise.all(batchPromises);
        }
        
        return billingInfoMap;
    }, []);

    const calculateAmountAndPath = (event, billingInfo, status) => {
        // For No Show events, return fixed amount with no path
        if (status === 'No Show') {
            return { amount: 25, billingPath: null };
        }
        
        if (!billingInfo || !event?.event_name) {
            return { amount: 'Loading...', billingPath: null };
        }
        
        const service = getServiceName(event.event_name);
        const serviceKey = service === 'Occupational Therapy' ? 'OT' : 'ST';
    
        if (!billingInfo[serviceKey]) {
            return { 
                amount: `This patient doesn't have billing info for service ${serviceKey}`,
                billingPath: null 
            };
        }
        
        const serviceInfo = billingInfo[serviceKey];
        
        if (!serviceInfo.PrimaryMethod) {
            return { 
                amount: `This patient doesn't have service info primary method`,
                billingPath: null 
            };
        }
    
        if (serviceInfo.PrimaryMethod === 'Out Of Pocket') {
            return {
                amount: serviceInfo.ViaOop?.OopRate || 'N/A',
                billingPath: `${serviceKey}.ViaOop.OopRate`
            };
        }
    
        if (serviceInfo.PrimaryMethod === 'Insurance') {
            const insuranceInfo = serviceInfo.ViaIns;
            const isInNetwork = insuranceInfo.InNetwork.Applies;
            const networkType = isInNetwork ? 'InNetwork' : 'OutOfNetwork';
            const networkInfo = insuranceInfo[networkType];
    
            if (!networkInfo) return { amount: 'Loading...', billingPath: null };
    
            const deductType = networkInfo.WhichDeductApplies === 'Individual' ? 'Ind' : 'Fam';
            const deductKey = `${deductType}DeductAmt`;
    
            return {
                amount: networkInfo.ChargePerSess,
                billingPath: `${serviceKey}.ViaIns.${networkType}.${deductKey}`
            };
        }
    
        return { amount: 'N/A', billingPath: null };
    };

    // Fetch patient names in batches
    useEffect(() => {
        const loadPatientNames = async () => {
            if (patientIdsToFetch.length === 0) return;
            
            setLoadingNames(true);
            
            // First try batch fetch (if implemented)
            let newNames = await batchFetchPatientNames(patientIdsToFetch);
            
            // If batch fetch doesn't return results, fall back to individual fetches
            if (Object.keys(newNames).length === 0) {
                newNames = await individualFetchPatientNames(patientIdsToFetch);
            }
            
            if (Object.keys(newNames).length > 0) {
                const updatedNames = {...patientNames, ...newNames};
                setPatientNames(updatedNames);
                
                // Update the cache
                patientCache.names = updatedNames;
            }
            
            setLoadingNames(false);
        };
        
        loadPatientNames();
    }, [patientIdsToFetch, batchFetchPatientNames, individualFetchPatientNames, patientNames]);

    // Fetch billing info in batches
    useEffect(() => {
        const loadBillingInfo = async () => {
            if (billingIdsToFetch.length === 0) return;
            
            const newBillingInfo = await batchFetchBillingInfo(billingIdsToFetch);
            
            if (Object.keys(newBillingInfo).length > 0) {
                const updatedBillingInfo = {...patientBillingInfo, ...newBillingInfo};
                setPatientBillingInfo(updatedBillingInfo);
                
                // Update the cache
                patientCache.billingInfo = updatedBillingInfo;
            }
        };
        
        loadBillingInfo();
    }, [billingIdsToFetch, batchFetchBillingInfo, patientBillingInfo]);

    const handleButtonClick = (event) => {
        if (status === 'Scheduled') {
            console.log('Alert Provider clicked for:', event);
        } else {
            const {amount, billingPath } = calculateAmountAndPath(event, patientBillingInfo[event.patient_id], status);
            console.log("Sending to charge page:", {event, status, amount, billingPath});
            navigate('/Charge', { 
                state: { 
                    eventData: event,
                    status: status, 
                    amount: amount,
                    billingPath: billingPath
                } 
            });
        }
    };

    return (
        <div className="overflow-x-auto">
            <table className="w-full border-collapse">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="p-4 text-left font-semibold text-gray-600">Provider</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Service</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Patient ID</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Patient Name</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Date</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Time</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Status</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Amount</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredEvents.map((event, index) => {
                        const { date, time } = formatDateTime(
                            event.start_time,
                            event.end_time
                        );

                        const { amount } = calculateAmountAndPath(
                            event, 
                            patientBillingInfo[event.patient_id], 
                            status
                        );
                        
                        return (
                            <tr key={index} className="border-b border-gray-200 hover:bg-gray-50">
                                <td className="p-4">{event.pr_id}</td>
                                <td className="p-4">{getServiceName(event.event_name)}</td>
                                <td className="p-4">{event.patient_id}</td>
                                <td className="p-4">{patientNames[event.patient_id] || 'Loading...'}</td>
                                <td className="p-4">{date}</td>
                                <td className="p-4">{time}</td>
                                <td className="p-4">{status}</td>
                                <td className="p-4">${typeof amount === 'number' ? amount.toFixed(2) : amount}</td>
                                <td className="p-4">
                                    <button
                                        onClick={() => handleButtonClick(event)}
                                        className={`px-4 py-2 rounded-lg text-white ${
                                            status === 'Scheduled'
                                                ? 'bg-yellow-500 hover:bg-yellow-600'
                                                : 'bg-green-500 hover:bg-green-600'
                                        }`}
                                    >
                                        {status === 'Scheduled' ? 'Alert Provider' : 'Charge'}
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

const CustomersTable = () => {
    const [customers, setCustomers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCustomers = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/list_square_customers', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();
                
                // Parse the nested body string if needed
                let customerData = [];
                if (data && data.body) {
                    if (typeof data.body === 'string') {
                        const parsedBody = JSON.parse(data.body);
                        customerData = parsedBody.customers || [];
                    } else if (data.body.customers) {
                        customerData = data.body.customers;
                    }
                } else if (data && data.customers) {
                    customerData = data.customers;
                }
                
                setCustomers(customerData);
            } catch (error) {
                console.error('Error fetching customers:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCustomers();
    }, []);

    const formatAddress = (address) => {
        if (!address) return 'No address provided';
        
        const addressParts = [];
        if (address.address_line_1) addressParts.push(address.address_line_1);
        if (address.address_line_2) addressParts.push(address.address_line_2);
        if (address.locality) addressParts.push(address.locality);
        if (address.administrative_district_level_1) addressParts.push(address.administrative_district_level_1);
        if (address.postal_code) addressParts.push(address.postal_code);
        if (address.country) addressParts.push(address.country);
        
        return addressParts.join(', ') || 'No address details available';
    };

    const handleAddCustomer = () => {
        setModalType('addCustomer');
        setShowModal(true);
    };

    const handleCardAction = (customer, action) => {
        setSelectedCustomer(customer);
        setModalType(action);
        setShowModal(true);
    };

    return (
        <div className="overflow-x-auto">
            <div className="flex justify-end mb-4">
                <button
                    onClick={handleAddCustomer}
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                    Add Customer
                </button>
            </div>
            
            {loading ? (
                <div className="flex justify-center my-8">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                </div>
            ) : (
                <table className="w-full border-collapse">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="p-4 text-left font-semibold text-gray-600">Name</th>
                            <th className="p-4 text-left font-semibold text-gray-600">Email</th>
                            <th className="p-4 text-left font-semibold text-gray-600">Phone</th>
                            <th className="p-4 text-left font-semibold text-gray-600">Address</th>
                            <th className="p-4 text-left font-semibold text-gray-600">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customers.length > 0 ? (
                            customers.map((customer, index) => (
                                <tr key={customer.id || index} className="border-b border-gray-200 hover:bg-gray-50">
                                    <td className="p-4">{`${customer.given_name || ''} ${customer.family_name || ''}`}</td>
                                    <td className="p-4">{customer.email_address || 'N/A'}</td>
                                    <td className="p-4">{customer.phone_number || 'N/A'}</td>
                                    <td className="p-4">{formatAddress(customer.address)}</td>
                                    <td className="p-4">
                                        <button
                                            onClick={() => handleCardAction(customer, 'addCard')}
                                            className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                                        >
                                            Add Card
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="p-4 text-center">No customers found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
            
            {showModal && (
                <div className="modal"> {/* Replace with actual modal code */}
                    <h2>{modalType === 'addCustomer' ? 'Add Customer' : modalType === 'editCard' ? 'Edit Card' : 'Add Card'}</h2>
                    {/* Modal content goes here */}
                    <button onClick={() => setShowModal(false)}>Close</button>
                </div>
            )}
        </div>
    );
};

const ChargesTable = () => {
    const [charges, setCharges] = useState([]);

    useEffect(() => {
        const fetchCharges = async () => {
            try {
                const response = await fetch('https://your-api-endpoint/charges', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();
                setCharges(data);
            } catch (error) {
                console.error('Error fetching charges:', error);
            }
        };

        fetchCharges();
    }, []);

    return (
        <div className="overflow-x-auto">
            <table className="w-full border-collapse">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="p-4 text-left font-semibold text-gray-600">Patient Name</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Service Date</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Amount Charged</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {charges.map((charge, index) => (
                        <tr key={index} className="border-b border-gray-200 hover:bg-gray-50">
                            <td className="p-4">{charge.patientName}</td>
                            <td className="p-4">{format(new Date(charge.serviceDate), 'MMMM d, yyyy')}</td>
                            <td className="p-4">${charge.amountCharged}</td>
                            <td className="p-4">{charge.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


// Rest of the components (CustomersTable, ChargesTable) remain the same...

const getServiceName = (evName) => {
    switch (evName) {
        case 'OT Appointment':
            return 'Occupational Therapy';
        case 'ST Appointment':
            return 'Speech Therapy';
        default:
            return 'N/A';
    }
};

const formatDateTime = (startTime, endTime) => {
    try {
        if (!startTime || !endTime) {
            return { date: 'N/A', time: 'N/A' };
        }
    
        const start = parseISO(startTime);
        const end = parseISO(endTime);
        
        if (!isValid(start) || !isValid(end)) {
            return { date: 'N/A', time: 'N/A' };
        }
    
        return {
            date: format(start, 'MMMM do'),
            time: `${format(start, 'h:mm')}-${format(end, 'h:mm')}`
        };
    } catch (error) {
        console.error('Error formatting date time:', error);
        return { date: 'N/A', time: 'N/A' };
    }
};

const DateSelector = ({ selectedDate, onDateChange }) => {
    return (
        <div className="flex items-center gap-4 mt-4 mb-2">
            <button
                onClick={() => onDateChange(subDays(selectedDate, 1))}
                className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 flex items-center gap-2"
            >
                <ChevronDoubleLeftIcon className="w-4 h-5" />
            </button>
            
            <div className="flex items-center gap-2 bg-gray-100 px-4 py-2 rounded-lg">
                <Calendar className="w-5 h-5 text-gray-500" />
                <span className="font-medium">{format(selectedDate, 'MMMM d, yyyy')}</span>
            </div>

            <button
                onClick={() => onDateChange(addDays(selectedDate, 1))}
                className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 flex items-center gap-2"
            >
                <ChevronDoubleRightIcon className="w-4 h-5" />
            </button>

            <button
                onClick={() => onDateChange(new Date())}
                className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 flex items-center gap-2"
            >
                Today
            </button>
        </div>
    );
};

const ChargingTable = () => {
    const [activeTab, setActiveTab] = useState('confirmed');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [scheduledEvents, setScheduledEvents] = useState([]);
    const [confirmedEvents, setConfirmedEvents] = useState([]);
    const [noShowEvents, setNoShowEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchEvents = async () => {
            setIsLoading(true);
            try {
                const formattedDate = format(selectedDate, 'yyyy-MM-dd');
        
                const requestBody = {
                    start_date: formattedDate,
                    end_date: formattedDate
                };
        
                const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_chargeable_events', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody)
                });
        
                const data = await response.json();
                let eventData = typeof data.body === 'string' ? JSON.parse(data.body) : data.body;
                
                // Filter out patient_id === 0 events here
                eventData = eventData.filter(event => event.patient_id !== '0' && event.patient_id !== 0);
                
                setScheduledEvents(eventData.filter(event => event.ev_status === 'A'));
                setConfirmedEvents(eventData.filter(event => event.ev_status === 'Y'));
                setNoShowEvents(eventData.filter(event => event.ev_status === 'N'));
            } catch (error) {
                console.error('Error fetching events:', error);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchEvents();
    }, [selectedDate]);

    // Reuse the CustomerTable and ChargesTable components as they were

    return (
        <div>
            <Header />
            <div className="p-6 max-w-7xl mx-auto mt-12">
                <DateSelector 
                    selectedDate={selectedDate}
                    onDateChange={setSelectedDate}
                />
                
                <div className="mb-8">
                    <div className="border-b border-gray-200">
                        <nav className="flex -mb-px">
                            <button
                                onClick={() => setActiveTab('confirmed')}
                                className={`mr-8 py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'confirmed'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                {confirmedEvents.length} Confirmed
                            </button>
                            <button
                                onClick={() => setActiveTab('scheduled')}
                                className={`mr-8 py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'scheduled'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                {scheduledEvents.length} Scheduled
                            </button>
                            <button
                                onClick={() => setActiveTab('noshow')}
                                className={`mr-8 py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'noshow'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                {noShowEvents.length} No Show
                            </button>
                            <button
                                onClick={() => setActiveTab('customers')}
                                className={`mr-8 ml-auto py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'customers'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                 Customers
                            </button>
                            <button
                                onClick={() => setActiveTab('charges')}
                                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'charges'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                 Charges
                            </button>
                        </nav>
                    </div>
                </div>

                {isLoading ? (
                    <div className="flex justify-center my-8">
                        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                    </div>
                ) : (
                    <div className="mt-6">
                        {activeTab === 'confirmed' && (
                            <EventsTable events={confirmedEvents} status="Confirmed" />
                        )}
                        {activeTab === 'scheduled' && (
                            <EventsTable events={scheduledEvents} status="Scheduled" />
                        )}
                        {activeTab === 'noshow' && (
                            <EventsTable events={noShowEvents} status="No Show" />
                        )}
                        {activeTab === 'customers' && (
                            <CustomersTable />
                        )}
                        {activeTab === 'charges' && (
                            <ChargesTable />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChargingTable;