import React, { useState, Fragment, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { format, addDays, subDays, addMonths, subMonths } from 'date-fns';
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, eachDayOfInterval, isSameDay, isSameMonth } from 'date-fns';
import Header from "./Header";
import {
  ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, Cog6ToothIcon, ClockIcon, MapPinIcon, CommandLineIcon, UserIcon} from '@heroicons/react/24/solid';
import { Menu, MenuItem, MenuButton, MenuItems, Transition } from '@headlessui/react';
import CreateEventModal from './CreateEventModal';
import FindSlotModal from './FindSlotModal';
import { Prev } from 'react-bootstrap/esm/PageItem';
import axios from 'axios';
import { useUser } from './UserContext';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
 
const CurrentTimeIndicator = ({ currentTimePos, containerWidth = '23.4%' }) => {
  if (!currentTimePos) return null;

  return (
    <div 
      className="absolute left-20 flex items-center group transition-opacity duration-300 hover:opacity-80 z-20"
      style={{ 
        top: `${currentTimePos}px`, 
        width: containerWidth 
      }}
    >
      {/* Time marker dot - increased z-index and adjusted positioning */}
      <div className="relative z-30">
        <div className="absolute -left-1.5 -top-1.5 w-3 h-3 bg-red-500 rounded-full shadow-md" />
      </div>
      
      {/* Time indicator line */}
      <div className="w-full h-0.5 bg-red-500 shadow-sm" />
    </div>
  );
};

export default function Example() {
  const { userName, userRole } = useUser();
  const navigate = useNavigate();

  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [showFindSlot, setShowFindSlot] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [provider, setProvider] = useState('');
  const [events, setEvents] = useState([]);
  const [providerName, setProviderName] = useState('');
  const [location, setLocation] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [getProviders, setGetProviders] = useState([]);
  const [selectedProvID, setSelectedProvId] = useState(null);
  const statusOptions = ['Confirmed', 'Scheduled', 'Canceled by Provider', 'Canceled by Patient'];
  const [openDropdowns, setOpenDropdowns] = useState({}); // Default status
  const [eventStatuses, setEventStatuses] = useState({}); // Default status
  const [currentTimePos, setCurrentTimePos] = useState(null);
  const [patientInfo, setPatientInfo] = useState({});
  const PROVIDER_STORAGE_KEY = 'selectedProviderInfo';

  const saveProviderToSession = (providerId, providerName) => {
    sessionStorage.setItem(PROVIDER_STORAGE_KEY, JSON.stringify({
      id: providerId,
      name: providerName,
      timestamp: new Date().getTime()
    }));
  };
  
  const getProviderFromSession = () => {
    const stored = sessionStorage.getItem(PROVIDER_STORAGE_KEY);
    if (!stored) return null;
  
    const data = JSON.parse(stored);
    const storedTime = new Date(data.timestamp);
    const now = new Date();
    
    // Clear if it's a different day or more than 8 hours old
    if (storedTime.getDate() !== now.getDate() || 
        (now.getTime() - storedTime.getTime()) > (60 * 60 * 1000)) {
      sessionStorage.removeItem(PROVIDER_STORAGE_KEY);
      return null;
    }
    
    return data;
  };

  const safeParseDateWithFallback = (dateInput) => {
    try {
      if (!dateInput) {
        return null;
      }

      // If dateInput is already a Date object, return it if valid
      if (dateInput instanceof Date) {
        if (!isNaN(dateInput.getTime())) {
          return dateInput;
        }
        return null;
      }
  
      // Handle ISO strings with timezone offset (e.g. "2025-01-19T13:30:00+00:00")
      if (typeof dateInput === 'string') {
        if (dateInput.includes('T')) {
          const date = new Date(dateInput);
          if (!isNaN(date.getTime())) {
            return date;
          }
        }
    
        // Handle date-only strings (e.g. "2025-01-19")
        if (dateInput.length === 10) {
          const [year, month, day] = dateInput.split('-').map(Number);
          if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
            const date = new Date(year, month - 1, day);
            if (!isNaN(date.getTime())) {
              return date;
            }
          }
        }
      }
  
      // Try parsing as a regular date string
      const date = new Date(dateInput);
      if (!isNaN(date.getTime())) {
        return date;
      }
  
      return null;
    } catch (error) {
      console.error('Date parsing error:', error, 'for date input:', dateInput);
      return null;
    }
  };

  const processEvents = (events) => {
    return events.map(event => {
      const parsedStartTime = safeParseDateWithFallback(event.start_time);
      const parsedEndTime = safeParseDateWithFallback(event.end_time);
  
      // Add validation for required fields
      if (!parsedStartTime || !parsedEndTime) {
        console.warn('Invalid event dates:', {
          eventId: event.event_id,
          start_time: event.start_time,
          end_time: event.end_time,
          parsedStart: parsedStartTime,
          parsedEnd: parsedEndTime
        });
        return null;
      }
  
      return {
        ...event,
        // Keep the original string versions but add parsed versions
        start_time: event.start_time,
        end_time: event.end_time,
        parsedStartTime: parsedStartTime,
        parsedEndTime: parsedEndTime
      };
    }).filter(Boolean); // Remove null events
  };

  const handleEventClick = (event) => {
    const startDate = new Date(event.start_time);
    const formattedDate = format(startDate, 'yyyy-MM-dd');
    const eventId = `${event.event_id}-${event.event_schedule_id}-${formattedDate}`;
    const currentPage = "Calendar";
    navigate(`/modifyevent/${eventId}`, {
      state: { event, page: currentPage } 
    });
  };

  const fetchPatientInfo = useCallback(async (patientId) => {
    if (patientId && patientId !== 'null') {
      console.log(`Fetching patient info for ID: ${patientId}`);
      try {
        const response = await axios.get('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/find_patient', {
          headers: {
            PatientID: patientId,
            'Content-Type': 'application/json'
          }
        });
        
        console.log('Raw response data:', response.data);
  
        if (response.data && response.data.body) {
          // Parse the JSON string in the body
          const parsedBody = JSON.parse(response.data.body);
          console.log('Parsed body:', parsedBody);
  
          if (Array.isArray(parsedBody) && parsedBody.length > 0 && parsedBody.length < 2) {
            const patientData = parsedBody[0];
            if (patientData.FirstName && patientData.LastName) {
              console.log(`Patient info received for ID ${patientId}: ${patientData.FirstName} ${patientData.LastName}`);
              return {
                firstName: patientData.FirstName,
                lastName: patientData.LastName
              };
            } else {
              console.log(`Incomplete patient data received for ID ${patientId}`);
            }
          } else if (Array.isArray(parsedBody) && parsedBody.length > 1) {
            const patientData = parsedBody[1];
            if (patientData.FirstName && patientData.LastName) {
              console.log(`Patient info received for ID ${patientId}: ${patientData.FirstName} ${patientData.LastName}`);
              return {
                firstName: patientData.FirstName,
                lastName: patientData.LastName
              };
            } else {
              console.log(`Incomplete patient data received for ID ${patientId}`);
            }
          } else {
            console.log(`No patient data found in the response for ID ${patientId}`);
          }
        } else {
          console.log(`Unexpected response structure for ID ${patientId}`);
        }
      } catch (error) {
        console.error('Error fetching patient info:', error);
        // Log the full error response for debugging
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    } else {
      console.log(`Invalid patient ID: ${patientId}`);
    }
    return null;
  }, []);

  useEffect(() => {
    const fetchPatientInfoForEvents = async () => {
      console.log('Fetching patient info for events');
      const patientInfoPromises = events.map(async (event) => {
        if (event.pt_id && event.pt_id !== 'null') {
          const info = await fetchPatientInfo(event.pt_id);
          return { [event.pt_id]: info };
        }
        return null;
      });
  
      const patientInfoResults = await Promise.all(patientInfoPromises);
      const newPatientInfo = patientInfoResults.reduce((acc, info) => {
        if (info) {
          return { ...acc, ...info };
        }
        return acc;
      }, {});
  
      console.log('Setting patient info:', newPatientInfo);
      setPatientInfo(newPatientInfo);
    };
  
    fetchPatientInfoForEvents();
  }, [events, fetchPatientInfo]);

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
  
    try {
      const params = new URLSearchParams();
      if (location) params.append('location', location);
      if (specialty) params.append('specialty', specialty);
      if (providerName) params.append('name', providerName + '%');
  
      const response = await axios.get(`https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_providers?${params.toString()}`);
  
      let providersArray = [];
  
      if (typeof response.data === 'string') {
        // Custom parsing for the specific string format
        const match = response.data.match(/body=(\[.*?\])/s);
        if (match && match[1]) {
          try {
            const bodyContent = match[1].replace(/'/g, '"'); // Replace single quotes with double quotes
            const parsedBody = JSON.parse(bodyContent);
            if (Array.isArray(parsedBody)) {
              providersArray = parsedBody.map(provider => ({
                id: provider.pr_id,
                name: provider.pr_name
              }));
            }
          } catch (parseError) {
            console.error('Error parsing body content:', parseError);
          }
        } else {
          console.error('Unable to extract body content from response');
        }
      } else if (typeof response.data === 'object' && response.data !== null) {
        // Handle case where response.data is already an object
        if (response.data.body && Array.isArray(response.data.body)) {
          providersArray = response.data.body.map(provider => provider.pr_name);
        } else {
          console.error('Unexpected data structure:', response.data);
        }
      } else {
        console.error('Unexpected response type:', typeof response.data);
      }
  
      setGetProviders(providersArray);
    } catch (error) {
      console.error('Error calling Lambda function:', error);
      setGetProviders([]);
    }
  };

  const updateCurrentDate = (date) => {
    setCurrentDate(date);
    setSelectedDate(date);
    handleProviderOrDateChange(selectedProvID, date);
  };

  const renderMini = () => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart, { weekStartsOn: 1 }); // Start on Monday
    const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });
  
    const rows = [];
    let days = [];
    let day = startDate;
    const today = new Date();
  
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const cloneDay = day;
        days.push(
          <div
            key={day.toString()}
            className={classNames(
              'py-1.5',
              !isSameMonth(day, monthStart) && 'bg-gray-50 text-gray-400',
              isSameMonth(day, monthStart) && 'bg-white',
              isSameDay(day, today) && 'text-indigo-600',
              isSameDay(day, selectedDate) && 'font-semibold'
            )}
          >
            <button
              onClick={() => updateCurrentDate(cloneDay)}
              className="w-full h-full flex items-center justify-center"
            >
              <time
                dateTime={format(day, 'yyyy-MM-dd')}
                className={classNames(
                  'flex h-7 w-7 items-center justify-center rounded-full',
                  isSameDay(day, selectedDate) && 'bg-blue-700 text-white'
                )}
              >
                {format(day, 'd')}
              </time>
            </button>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div key={day.toString()} className="grid grid-cols-7 gap-px">
          {days}
        </div>
      );
      days = [];
    }
  
    return (
      <div className="bg-gray-200 p-px rounded-lg shadow ring-1 ring-gray-200">
        {rows}
      </div>
    );
  };

  const goForward = () => {
    const newDate = addDays(currentDate, 1);
    setCurrentDate(newDate);
    handleProviderOrDateChange(selectedProvID, newDate);
  };

  const goToday = () => {
    const newDate = new Date();
    setCurrentDate(newDate);
    handleProviderOrDateChange(selectedProvID, newDate);
  };

  const goBackward = () => {
    const newDate = subDays(currentDate, 1);
    setCurrentDate(newDate);
    handleProviderOrDateChange(selectedProvID, newDate);
  };

  const handleProviderOrDateChange = (newProviderId, newDate) => {
    const formattedDate = format(newDate || currentDate, 'yyyy-MM-dd');
    const provId = newProviderId || selectedProvID;

    if (provId) {
      const payload = {
        provider_id: parseInt(provId),
        start_date: formattedDate,
        end_date: formattedDate
      };

      fetchEvents(payload);
    } else {
      setEvents([]);
    }
  };

  const fetchEvents = async (payload) => {
    try {
      const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get-provider-events', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Raw response:', data);
      
      // Ensure we're working with an array
      const parsedData = Array.isArray(data) ? data : JSON.parse(data);
      console.log('Parsed data:', parsedData);
  
      // Process and validate events
      const processedEvents = processEvents(parsedData);
      setEvents(processedEvents); // Update the state with processed events
    } catch (error) {
      console.error('Error fetching events:', error);
      setEvents([]); // Set empty array on error
    }
  };

  const callLambdaFunction = async () => {
    if (!selectedProvID) return;

    const formattedDate = format(currentDate, 'yyyy-MM-dd');
    const payload = {
      provider_id: parseInt(selectedProvID),
      start_date: formattedDate,
      end_date: formattedDate
    };
  
    try {
      const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get-provider-events', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Lambda function response:', data);
      
      // Parse JSON string (data) into an array
      const parsedData = JSON.parse(data);
      console.log('Parsed data: ', parsedData);

      setEvents(parsedData);
    } catch (error) {
      console.error('Error calling Lambda function:', error);
    }
  };

  const toggleDropdown = (eventId) => {
    setOpenDropdowns(prev => ({
      ...prev, 
      [eventId]: !prev[eventId]
    }));
  };

  const updateStatus = (eventId, newStatus) => {
    setEventStatuses(prev => ({
      ...prev,
      [eventId]: newStatus
    }));
  };

  const changeEventStatus = async (uniqueEventId, excepDate, cancelReason, excStatus) => {
    const [eventId, eventSchId, formattedDate] = uniqueEventId.split('-');
    
    const payload = {
      event_id: eventId,
      event_schedule_id: eventSchId,
      excep_date: excepDate,
      cancel_reason: cancelReason,
      exc_status: excStatus,
    };
  
    try {
      const response = await fetch(
        'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/create_exception_event',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('create_exception_event response:', data);
  
      const updatedEvent = {
        event_id: parseInt(eventId),
        event_schedule_id: parseInt(eventSchId),
        ev_status: excStatus,
        cancel_reason: cancelReason,
        start_time: excepDate
      };
  
      console.log('Returning updated event:', updatedEvent);
      return updatedEvent;
  
    } catch (error) {
      console.error('Error updating event status:', error);
      throw error;
    }
  };

  const getLocationAddress = (locationCode) => {
    switch (locationCode) {
      case 8:
        return '8 Johnson St';
      case 225:
        return '225 Richmond Hill Rd';
      case 445:
        return '445 Forest Ave';
      default:
        return 'Unknown Location';
    }
  };

  const getTimeBlocks = useCallback(() => {
    const startHour = userRole === 'Admin' ? 6 : 8;
    const endHour = userRole === 'Admin' ? 20 : 19;
    const baseDate = new Date(selectedDate);
    baseDate.setHours(0, 0, 0, 0);
  
    let timeBlocks = [];
    for (let hour = startHour; hour <= endHour; hour++) {
      timeBlocks.push(new Date(baseDate.getTime() + hour * 3600000));
      if (hour < endHour) {
        timeBlocks.push(new Date(baseDate.getTime() + hour * 3600000 + 1800000));
      }
    }
  
    // Remove the last block (8:00 PM) for non-admin users
  
    return timeBlocks;
  }, [userRole, selectedDate]);

  const handleStatusChange = useCallback(async (e, uniqueEventId, startTime) => {
    const parsedStartTime = safeParseDateWithFallback(startTime);
    if (!parsedStartTime) {
      console.error('Invalid start time:', startTime);
      return;
    }

    const newStatus = e.target.value;
    console.log('New status selected:', newStatus, 'for event on', format(parsedStartTime, 'yyyy-MM-dd'));
    
    let excStatus;
    let cancelReason = '';
    switch (newStatus) {
      case 'Canceled by Patient':
      case 'Canceled by Provider':
        excStatus = 'C';
        cancelReason = newStatus;
        break;
      case 'Scheduled':
        excStatus = 'A';
        break;
      case 'No Show':
        excStatus = 'N';
        break;
      case 'Confirmed':
        excStatus = 'Y';
        break;
      default:
        excStatus = 'A';
    }
  
    try {
      // Safely format the date
      const excepDate = format(parsedStartTime, 'yyyy-MM-dd');
      if (!excepDate) {
        throw new Error('Invalid date format');
      }
  
      // Optimistic update with safe date handling
      setEvents(prevEvents => prevEvents.map(evt => {
        const parsedDate = safeParseDateWithFallback(evt.start_time);
        if (!parsedDate) return evt;

        const evtId = `${evt.event_id}-${evt.event_schedule_id}-${format(parsedDate, 'yyyy-MM-dd')}`;
        if (evtId === uniqueEventId) {
          return { 
            ...evt, 
            ev_status: excStatus, 
            cancel_reason: cancelReason 
          };
        }
        return evt;
      }));
  
      const updatedEvent = await changeEventStatus(uniqueEventId, excepDate, cancelReason, excStatus);
      console.log('Updated event:', updatedEvent);
  
      // Update with server response
      setEvents(prevEvents => prevEvents.map(evt => {
        const parsedDate = safeParseDateWithFallback(evt.start_time);
        if (!parsedDate) return evt;

        const evtId = `${evt.event_id}-${evt.event_schedule_id}-${format(parsedDate, 'yyyy-MM-dd')}`;
        if (evtId === uniqueEventId) {
          return { 
            ...updatedEvent, 
            start_time: evt.start_time 
          };
        }
        return evt;
      }));
  
      // Fetch fresh data
      await handleProviderOrDateChange(selectedProvID, currentDate);
    } catch (error) {
      console.error('Failed to update event status:', error);
      alert('Failed to update event status. Please try again.');
    }
  }, [changeEventStatus, handleProviderOrDateChange, selectedProvID, currentDate]);
  
  const getEventStatus = (event) => {
    if (event.ev_status === 'A') return 'Scheduled';
    if (event.ev_status === 'Y') return 'Confirmed';
    if (event.ev_status === 'C') return event.cancel_reason || 'Canceled';
    if (event.ev_status === 'N') return 'No Show';
    return 'Scheduled'; // Default value
  };
  
  const getEventClass = (event) => {
    return event.ev_status === 'C' || event.ev_status === 'N'
      ? 'bg-red-50' 
      : 'bg-blue-50';
  };

  useEffect(() => {
    const initialStatuses = events.reduce((acc, event) => {
      acc[event.event_id] = 'Scheduled';
      return acc;
    }, {});
    setEventStatuses(initialStatuses);
    handleSubmit();
  }, [events]);

  useEffect(() => {
    if (getProviders.length > 0) {
      const savedProvider = getProviderFromSession();
      
      if (savedProvider && !provider) {
        const providerStillExists = getProviders.find(p => p.id === savedProvider.id);
        
        if (providerStillExists) {
          setProvider(savedProvider.name);
          setSelectedProvId(savedProvider.id);
          handleProviderOrDateChange(savedProvider.id, currentDate);
          return;
        }
      }
      
      // Fall back to userName match only if no saved session
      if (userName && !provider && !savedProvider) {
        const matchedProvider = getProviders.find(provider => 
          provider.name.toLowerCase() === userName.toLowerCase()
        );
        if (matchedProvider) {
          setProvider(matchedProvider.name);
          setSelectedProvId(matchedProvider.id);
          handleProviderOrDateChange(matchedProvider.id, currentDate);
        }
      }
    }
  }, [userName, getProviders, provider, currentDate]);

  useEffect(() => {
    // Function to calculate the position of the current time
    const calculateTimePosition = () => {
      const currentDate = new Date();
      const startHour = userRole === 'Admin' ? 6 : 8;
      const blockHeight = 64; // 4rem for each hour slot
      
      const currentHour = currentDate.getHours();
      const currentMinutes = currentDate.getMinutes();
    
      console.log('Current time:', currentHour, ':', currentMinutes);
    
      // For 2:35 PM (14:35), with start time of 8:00 AM
      // We need (14 - 8) = 6 hours difference
      const hoursDiff = currentHour - startHour;
      
      // Convert everything to minutes since start time
      const totalMinutesSinceStart = (hoursDiff * 60) + currentMinutes;
      
      // Each 30-min block is 32px (half of blockHeight)
      // So for each minute, we need (32/30) pixels
      const position = (totalMinutesSinceStart * (32/30)) + 32; // 32px for initial padding
    
      console.log('Total minutes since start:', totalMinutesSinceStart);
      console.log('Final position:', position);
      
      return position;
    };

    calculateTimePosition(); // Initial calculation

    const intervalId = setInterval(() => {
      calculateTimePosition(); // Update every minute
    }, 60000); // 1-minute interval

    return () => clearInterval(intervalId);
  }, [userRole]);

  return (
    <>
    <div className="z-30 sticky">
      <Header />
    </div>
    <div className="flex h-full flex-col">
      <div className="flex flex-none items-center pt-24 px-6">
        <div>
          <h1 className="text-lg font-semibold leading-6 text-gray-900">
            <time className="hidden sm:inline">
              {format(currentDate, 'MMMM d, yyyy')}
            </time>
          </h1>
          <p className="mt-1 text-sm text-gray-500">{format(currentDate, 'EEEE')}</p>
        </div>
        <div className="flex items-center">
          <div className="flex items-center rounded-md shadow-sm md:items-stretch ml-12">
            <button
              type="button"
              className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
              onClick={goBackward}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              onClick={goToday}
              className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
            >
              Today
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              type="button"
              onClick={goForward}
              className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden md:ml-4 md:flex md:items-center">
            <Menu as="div" className="relative">
              <MenuButton
                type="button"
                className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                Day view
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </MenuButton>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems className="focus:outline-none absolute right-0 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="py-1">
                    <MenuItem>
                        <Link to="/calendar" className='text-gray-700 block px-4 py-2 text-sm'>
                          Day View
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/weekview" className='text-gray-700 block px-4 py-2 text-sm'>
                          Week View
                        </Link>
                    </MenuItem>
                    {/* <MenuItem>
                        <Link to="/twoprovview" className='text-gray-700 block px-4 py-2 text-sm'>
                          Month View
                        </Link>
                    </MenuItem> */}
                    <MenuItem>
                        <Link to="/twoprovview" className='text-gray-700 block px-4 py-2 text-sm'>
                          Two Provider View
                        </Link>
                    </MenuItem>
                  </div>
                </MenuItems>
              </Transition>
            </Menu>
            <div className="ml-6 h-6 w-px bg-gray-300" />
            <button
              type="button"
              onClick = {() => setShowCreateEvent(true)}
              className="focus:outline-none ml-6 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Add Event
            </button>
            <CreateEventModal
              isOpen={showCreateEvent}
              onClose={() => setShowCreateEvent(false)}
            />
            <div className="ml-6 h-6 w-px bg-gray-300" />
            <button
              type="button"
              onClick = {() => setShowFindSlot(true)}
              className="focus:outline-none ml-6 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Find Appointment Slot
            </button>
            <FindSlotModal
              isOpen={showFindSlot}
              onClose={() => setShowFindSlot(false)}
            />
          </div>
          {/* MENU FOR SMALLER SCREEN W YEAR VIEW */}
          <Menu as="div" className=" ml-6 md:hidden">
            <MenuButton className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
              <span className="sr-only">Open menu</span>
              <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
            </MenuButton>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="focus:outline-none absolute right-0 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="py-1">
                  <MenuItem>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Create event
                      </a>
                    )}
                  </MenuItem>
                </div>
                <div className="py-1">
                  <MenuItem>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Go to today
                      </a>
                    )}
                  </MenuItem>
                </div>
                <div className="py-1">
                  <MenuItem>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Day view
                      </a>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Week view
                      </a>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Month view
                      </a>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Year view
                      </a>
                    )}
                  </MenuItem>
                </div>
              </MenuItems>
            </Transition>
          </Menu>
        </div>
        </div>
        <div className="flex items-center border-b border-gray-200 px-6 py-4">
          {/* Provider Dropdown Section */}
          <div className="flex items-center">
            <label htmlFor="provider" className="block mr-4 text-sm font-medium text-gray-900 dark:text-black">
              Provider
            </label>
            <div>
              <select 
                value={provider} 
                onChange={(e) => {
                  const selectedProvider = getProviders.find(p => p.name === e.target.value);
                  const newProviderId = selectedProvider ? selectedProvider.id : null;
                  
                  setProvider(e.target.value);
                  setSelectedProvId(newProviderId);
                  
                  if (selectedProvider) {
                    saveProviderToSession(newProviderId, e.target.value);
                  } else {
                    sessionStorage.removeItem(PROVIDER_STORAGE_KEY);
                  }
                  handleProviderOrDateChange(newProviderId, currentDate);
                }} 
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                id="grid-state"
              >
                <option>None</option>
                {getProviders.length > 0 ? (
                  [...getProviders]
                  .map(provider => ({
                    ...provider,
                    formattedName: provider.name.split(' ').reverse().join(', ')
                  }))
                  .sort((a, b) => a.formattedName.localeCompare(b.formattedName))
                  .map((provider) => (
                    <option key={provider.id} value={provider.name}>
                      {provider.formattedName}
                    </option>
                  ))
                ) : (
                  <option>No providers available</option>
                )}
              </select>
            </div>
          </div>

          {/* Search Section */}
          <div className="flex items-center ml-6">
            <p className="font-bold mr-4">Or Search By 1 Of The Following:</p>
            <form className="flex items-center" onSubmit={handleSubmit}>
              <div className="flex items-center">
                <label className="mr-2">Name:</label>
                <input
                  type="text"
                  className="rounded-md text-xs w-[8rem] h-8"
                  value={providerName}
                  onChange={(e) => setProviderName(e.target.value)}
                  placeholder="Provider Name"
                />
              </div>
              <div className="mx-4 h-6 w-px bg-gray-300" />
              <div className="flex items-center">
                <label className="mr-2">Location:</label>
                <input
                  type="text"
                  className="rounded-md text-xs w-[8rem] h-8"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  placeholder="8, 225, 445"
                />
              </div>
              <div className="mx-4 h-6 w-px bg-gray-300" />
              <div className="flex items-center">
                <label className="mr-2">Specialty:</label>
                <input
                  type="text"
                  className="rounded-md text-xs w-[8rem] h-8"
                  value={specialty}
                  onChange={(e) => setSpecialty(e.target.value)}
                  placeholder="OT or ST"
                />
              </div>
              <button 
                type="submit" 
                className="ml-4 px-4 py-1.5 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
              >
                Search
              </button>
            </form>
          </div>
        </div>
        <div className="flex flex-auto overflow-hidden bg-white">
    
    {/* Time column */}
<div className="flex-none relative z-10 border-r border-gray-200" style={{ width: '80px' }}>
  {getTimeBlocks().map((time, index) => (
    <div 
      key={time.toISOString()} 
      className="absolute w-full pr-2 text-right text-sm leading-5 text-gray-400"
      style={{ top: `${index * 4}rem`, height: '4rem', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
    >
      {index % 2 === 0 && (
        <span>
          {format(time, 'h:mm')}
          <span className="text-[0.75rem] ml-0.5">{format(time, 'a')}</span>
        </span>
      )}
    </div>
  ))}
</div>

<div ref={container} className="flex flex-auto flex-col overflow-auto">
  <div className="flex w-full flex-auto pt-8">
    <div className="grid flex-auto grid-cols-1 grid-rows-1">
      {/* Horizontal lines */}
      <div
        className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-200"
        style={{ 
          gridTemplateRows: `repeat(${getTimeBlocks().length}, minmax(4rem, 1fr))` 
        }}
      >
        {getTimeBlocks().map((time, index) => (
          <div key={time.toISOString()} className="relative">
            {/* Add a horizontal line for every time block */}
            <div className="absolute left-0 right-0 border-t border-gray-200"></div>
          </div>
        ))}
      </div>
              {/* Events */}
              <ol
        className="col-start-1 col-end-2 row-start-1 grid grid-cols-1"
        style={{
          gridTemplateRows: `repeat(${getTimeBlocks().length}, minmax(4rem, 1fr))`,
        }}
      >

                {events.map((event, index) => {
                  const startTime = safeParseDateWithFallback(event.start_time);
                  const endTime = safeParseDateWithFallback(event.end_time);
                  
                  if (!startTime || !endTime) return null;
                  const startHour = startTime.getHours();
                  const startMinutes = startTime.getMinutes();
                  const duration = (endTime - startTime) / (1000 * 60 * 30);
                  const isShortAppointment = duration <= 1;

                  const gridRowStart = (startHour - (userRole === 'Admin' ? 6 : 8)) * 2 + 1 + (startMinutes >= 30 ? 1 : 0);
                  const gridRowSpan = Math.ceil(duration);

                  const status = getEventStatus(event);
                  const eventClass = getEventClass(event);
                  const formattedDate = format(startTime, 'yyyy-MM-dd');
                  const uniqueEventId = `${event.event_id}-${event.event_schedule_id}-${formattedDate}`;

                  return (
                    <li
      key={`event-${index}`}
      className="relative mt-px flex"
      style={{
        gridRow: `${gridRowStart} / span ${gridRowSpan}`,
        gridColumn: '1',
      }}
    >
      {patientInfo[event.pt_id] ? (
                                  <div className="group absolute inset-1 flex rounded-lg p-2 text-xs leading-5 bg-slate-100 shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out overflow-hidden border-l-4 border-blue-500">
                                  <div className="flex-shrink-0 w-14 text-center mr-3">
                                    <p className={`${isShortAppointment ? 'text-lg' : 'text-2xl'} font-semibold text-gray-900`}>{format(startTime, 'dd')}</p>
                                    <p className={`${isShortAppointment ? 'text-xs -mt-[1px]' : 'text-sm'} font-medium text-gray-500`}>{format(startTime, 'MMM')}</p>
                                  </div>
                                  <div className='w-[1px] h-[100%] bg-gray-200'></div>
                                  <div className={`ml-3 flex-grow flex flex-col h-full ${isShortAppointment ? 'justify-between' : ''}`}>
                                    <div className="flex justify-between items-start w-full">
                                      <div className={`flex flex-col ${isShortAppointment ? 'mb-1' : 'flex-grow'}`}>
                                        <h3 className={`font-semibold ${isShortAppointment ? 'text-sm' : 'text-base'} text-gray-900 truncate`}>
                                          {`${patientInfo[event.pt_id].firstName} ${patientInfo[event.pt_id].lastName}`} - {event.event_name}
                                        </h3>
                                        <div className={`flex ${isShortAppointment ? 'flex-row items-center mt-1' : 'flex-col mt-1'}`}>
                                          <div className="flex items-center text-sm text-gray-600 mr-2">
                                            <ClockIcon className={`${isShortAppointment ? 'h-3 w-3' : 'h-4 w-4'} mr-1`} />
                                            <span className={`${isShortAppointment ? 'text-xs' : 'text-sm'}`}>
                                              {format(startTime, 'h:mm a')} - {format(endTime, 'h:mm a')}
                                            </span>
                                          </div>
                                          <div className="flex items-center text-sm text-gray-600">
                                            <MapPinIcon className={`${isShortAppointment ? 'h-3 w-3' : 'h-4 w-4'} mr-1`} />
                                            <span className={`truncate ${isShortAppointment ? 'text-xs' : 'text-sm'}`}>
                                              {getLocationAddress(event.ev_location)}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex items-center gap-2">
                                        <button
                                          onClick={() => handleEventClick(event)}
                                          className="px-3 py-1 text-sm font-medium rounded-full bg-blue-100 text-blue-700 hover:bg-blue-200"
                                        >
                                          View Details
                                        </button>
                                        <select
                                          value={status}
                                          onChange={(e) => handleStatusChange(e, uniqueEventId, startTime)}
                                          className="text-sm bg-gray-100 px-2 py-1 rounded-md shadow-sm border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        >
                                          {statusOptions.map((option) => (
                                            <option key={option} value={option}>{option}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    {!isShortAppointment && (
                                      <p className="mt-auto text-xs text-gray-400">
                                        Event ID: {event.event_id}, Schedule ID: {event.event_schedule_id}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div className="group absolute inset-1 flex rounded-lg p-2 text-xs leading-5 bg-slate-100 shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out overflow-hidden border-l-4 border-blue-500">
                                  <div className="flex-shrink-0 w-14 text-center mr-3">
                                    <p className={`${isShortAppointment ? 'text-lg' : 'text-2xl'} font-semibold text-gray-900`}>{format(startTime, 'dd')}</p>
                                    <p className={`${isShortAppointment ? 'text-xs -mt-[1px]' : 'text-sm'} font-medium text-gray-500`}>{format(startTime, 'MMM')}</p>
                                  </div>
                                  <div className='w-[1px] h-[100%] bg-gray-200'></div>
                                  <div className={`ml-3 flex-grow flex flex-col h-full ${isShortAppointment ? 'justify-between' : ''}`}>
                                    <div className="flex justify-between items-start w-full">
                                      <div className={`flex flex-col ${isShortAppointment ? 'mb-1' : 'flex-grow'}`}>
                                        <h3 className={`font-semibold ${isShortAppointment ? 'text-sm' : 'text-base'} text-gray-900 truncate`}>
                                          {event.event_name}
                                        </h3>
                                        <div className={`flex ${isShortAppointment ? 'flex-row items-center mt-1' : 'flex-col mt-1'}`}>
                                          <div className="flex items-center text-sm text-gray-600 mr-2">
                                            <ClockIcon className={`${isShortAppointment ? 'h-3 w-3' : 'h-4 w-4'} mr-1`} />
                                            <span className={`${isShortAppointment ? 'text-xs' : 'text-sm'}`}>
                                              {format(startTime, 'h:mm a')} - {format(endTime, 'h:mm a')}
                                            </span>
                                          </div>
                                          <div className="flex items-center text-sm text-gray-600">
                                            <MapPinIcon className={`${isShortAppointment ? 'h-3 w-3' : 'h-4 w-4'} mr-1`} />
                                            <span className={`truncate ${isShortAppointment ? 'text-xs' : 'text-sm'}`}>
                                              {getLocationAddress(event.ev_location)}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex items-center gap-2">
                                        <button
                                          onClick={() => handleEventClick(event)}
                                          className="px-3 py-1 text-sm font-medium rounded-full bg-blue-100 text-blue-700 hover:bg-blue-200"
                                        >
                                          View Details
                                        </button>
                                        <select
                                          value={status}
                                          onChange={(e) => handleStatusChange(e, uniqueEventId, startTime)}
                                          className="text-sm bg-gray-100 px-2 py-1 rounded-md shadow-sm border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        >
                                          {statusOptions.map((option) => (
                                            <option key={option} value={option}>{option}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    {!isShortAppointment && (
                                      <p className="mt-auto text-xs text-gray-400">
                                        Event ID: {event.event_id}, Schedule ID: {event.event_schedule_id}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              )}
    </li>
                  );
                })}
                <CurrentTimeIndicator 
                  currentTimePos={currentTimePos}
                  containerWidth="63.4%" // Or pass as a prop if this needs to be dynamic
                />
              </ol>
            </div>
          </div>
        </div>
        <div className="hidden w-1/2 max-w-md flex-none border-l border-gray-100 py-10 px-8 md:block">
          <div className="flex items-center text-center text-gray-900">
            <button
              type="button"
              onClick={() => setCurrentDate(subMonths(currentDate, 1))}
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="flex-auto font-semibold">{format(currentDate, 'MMMM yyyy')}</div>
            <button
              type="button"
              onClick={() => setCurrentDate(addMonths(currentDate, 1))}
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
            <div>S</div>
          </div>
          {renderMini()}
        </div>
      </div>
    </div>
    </>
  );
}
