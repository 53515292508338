import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';
import { useUser } from './UserContext';

const Alerts = () => {
  const { userName } = useUser();
  const [receivedAlerts, setReceivedAlerts] = useState([]);
  const [sentAlerts, setSentAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [receivedPage, setReceivedPage] = useState(1);
  const [sentPage, setSentPage] = useState(1);
  const ALERTS_PER_PAGE = 4;

  const [assignedAlert, setAssignedAlert] = useState({ 
    alertFrom: '', 
    alertTo: '', 
    description: '', 
    dueDate: '', 
    department: '', 
    importance: 'Medium', 
    alertType: 'Announcement',
    status: 'Received' 
  });
  const [departmentPeople, setDepartmentPeople] = useState([]);

  // Mock data for department people - in a real app, you'd fetch this from an API
  const departmentStaff = {
    'AD': ['John Admin', 'Sarah Admin', 'Mike Admin'],
    'BI': ['Lisa Billing', 'Tom Billing', 'Emma Billing'],
    'PC': ['David Coordinator', 'Julia Coordinator', 'Robert Coordinator'],
    'PV': ['Maria Provider', 'James Provider', 'Chen Provider'],
    'PR': ['Alex PR', 'Taylor PR', 'Jordan PR'],
    'AL': ['Everyone']
  };

  // Fetch alerts
  useEffect(() => {
    const fetchAlerts = async () => {
      if (!userName) return;
    
      try {
        const response = await axios.post(
          'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_alerts', 
          { user_name: userName }
        );
    
        // Parse the nested body string into JSON
        const alertsData = JSON.parse(response.data.body);
        
        // Separate received and sent alerts
        const received = alertsData.filter(alert => alert.alert_to === userName);
        const sent = alertsData.filter(alert => alert.alert_from === userName);
        
        setReceivedAlerts(received);
        setSentAlerts(sent);
        setError(null);
        
      } catch (error) {
        console.error('Error fetching alerts:', error);
        setError('Failed to load alerts');
      } finally {
        setLoading(false);
      }
    };
  
    fetchAlerts();
  }, [userName]);

  // Update department people when department changes
  useEffect(() => {
    if (assignedAlert.department) {
      setDepartmentPeople(departmentStaff[assignedAlert.department] || []);
    } else {
      setDepartmentPeople([]);
    }
  }, [assignedAlert.department]);

  // Format the timestamp to relative time
  const getTimeAgo = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);
    
    if (seconds < 60) return 'just now';
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) return `${minutes} minutes ago`;
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours} hours ago`;
    const days = Math.floor(hours / 24);
    return `${days} days ago`;
  };

  // Handle sending an alert
  const handleSendAlert = async () => {
    // Validate required fields
    if (!assignedAlert.department || !assignedAlert.alertTo || !assignedAlert.description) {
      alert('Please fill in all required fields');
      return;
    }

    try {
      const response = await axios.post(
        'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/create_alert', 
        {
          alertFrom: userName,
          alertTo: assignedAlert.alertTo,
          description: assignedAlert.description,
          alertType: assignedAlert.alertType,
          department: assignedAlert.department,
          importance: assignedAlert.importance,
          dueDate: assignedAlert.dueDate || null
        }
      );
  
      console.log('Alert created:', response.data);
      
      // Reset form
      setAssignedAlert({ 
        alertFrom: '', 
        alertTo: '', 
        description: '', 
        dueDate: '', 
        department: '', 
        importance: 'Medium', 
        alertType: 'Announcement',
        status: 'Received' 
      });
      
      // Optionally, refresh alerts or update local state
      alert('Alert sent successfully!');
    } catch (error) {
      console.error('Error sending Alert:', error);
      alert('Failed to send Alert');
    }
  };

  // Update alert status
  const handleUpdateAlertStatus = async (alertId, newStatus) => {
    try {
      const response = await axios.post(
        'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/update_alert_status', 
        {
          alert_id: alertId,
          new_status: newStatus
        }
      );

      // Update local state
      const updatedReceivedAlerts = receivedAlerts.map(alert => 
        alert.alert_id === alertId ? { ...alert, status: newStatus } : alert
      );
      setReceivedAlerts(updatedReceivedAlerts);

      alert('Alert status updated successfully!');
    } catch (error) {
      console.error('Error updating alert status:', error);
      alert('Failed to update alert status');
    }
  };

  // Pagination helpers
  const paginateAlerts = (alerts, currentPage) => {
    const startIndex = (currentPage - 1) * ALERTS_PER_PAGE;
    return alerts.slice(startIndex, startIndex + ALERTS_PER_PAGE);
  };

  const totalReceivedPages = Math.ceil(receivedAlerts.length / ALERTS_PER_PAGE);
  const totalSentPages = Math.ceil(sentAlerts.length / ALERTS_PER_PAGE);

  const paginatedReceivedAlerts = paginateAlerts(receivedAlerts, receivedPage);
  const paginatedSentAlerts = paginateAlerts(sentAlerts, sentPage);

  return (
    <div>
      <Header />

      {/* Received Alerts Section */}
      <div className='pt-20 pl-4'>
        <h2 className='font-bold text-blue-700 text-lg pb-2'>Alerts Sent to You</h2>
        <div className="px-4 py-6 bg-gray-50 rounded-lg shadow-sm mb-8">
          {loading ? (
            <div className="text-center">Loading alerts...</div>
          ) : error ? (
            <div className="text-red-500 text-center">{error}</div>
          ) : paginatedReceivedAlerts.length === 0 ? (
            <div className="text-gray-500 text-center">No alerts found</div>
          ) : (
            <div className="space-y-4">
              {paginatedReceivedAlerts.map((alert) => (
                <div key={alert.alert_id} className="bg-white p-4 rounded-md shadow-sm border border-gray-200">
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-sm font-semibold text-gray-600">
                      From: {alert.alert_from}
                    </span>
                    <span className="text-xs text-gray-400">
                      {getTimeAgo(alert.created_at)}
                    </span>
                  </div>
                  <p className="text-gray-700">{alert.description}</p>
                  <div className="mt-2 flex justify-between items-center">
                    <div className="flex gap-2">
                      <span className={`inline-block px-2 py-1 rounded text-xs ${
                        alert.importance === 'Urgent' ? 'bg-red-200 text-red-800' :
                        alert.importance === 'High' ? 'bg-orange-100 text-orange-800' :
                        alert.importance === 'Medium' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-blue-100 text-blue-800'
                      }`}>
                        {alert.importance}
                      </span>
                      <span className='inline-block px-2 py-1 rounded text-xs bg-green-100 text-green-800'>
                        {alert.status}
                      </span>
                      <span className='inline-block px-2 py-1 rounded text-xs bg-blue-100 text-blue-800'>
                        {alert.alert_type}
                      </span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <span className="text-sm text-gray-500">
                        {alert.due_at && `Due: ${new Date(alert.due_at).toLocaleDateString()}`}
                      </span>
                      <select 
                        value={alert.status} 
                        onChange={(e) => handleUpdateAlertStatus(alert.alert_id, e.target.value)}
                        className="text-sm border rounded px-1 py-0.5"
                      >
                        <option value="Received">Received</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Completed">Completed</option>
                        <option value="Denied">Denied</option>
                      </select>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {/* Pagination for Received Alerts */}
          <div className="flex justify-center mt-4 space-x-2">
            {Array.from({ length: totalReceivedPages }, (_, i) => i + 1).map(page => (
              <button
                key={page}
                onClick={() => setReceivedPage(page)}
                className={`px-3 py-1 rounded ${
                  receivedPage === page 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-gray-200 text-gray-700'
                }`}
              >
                {page}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Sent Alerts Section */}
      <div className='pl-4'>
        <h2 className='font-bold text-blue-700 text-lg pb-2'>Alerts You Sent</h2>
        <div className="px-4 py-6 bg-gray-50 rounded-lg shadow-sm mb-8">
          {loading ? (
            <div className="text-center">Loading alerts...</div>
          ) : error ? (
            <div className="text-red-500 text-center">{error}</div>
          ) : paginatedSentAlerts.length === 0 ? (
            <div className="text-gray-500 text-center">No alerts found</div>
          ) : (
            <div className="space-y-4">
              {paginatedSentAlerts.map((alert) => (
                <div key={alert.alert_id} className="bg-white p-4 rounded-md shadow-sm border border-gray-200">
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-sm font-semibold text-gray-600">
                      To: {alert.alert_to}
                    </span>
                    <span className="text-xs text-gray-400">
                      {getTimeAgo(alert.created_at)}
                    </span>
                  </div>
                  <p className="text-gray-700">{alert.description}</p>
                  <div className="mt-2 flex justify-between items-center">
                    <div className="flex gap-2">
                      <span className={`inline-block px-2 py-1 rounded text-xs ${
                        alert.importance === 'Urgent' ? 'bg-red-200 text-red-800' :
                        alert.importance === 'High' ? 'bg-orange-100 text-orange-800' :
                        alert.importance === 'Medium' ? 'bg-yellow-100 text-yellow-800' :
                        'bg-blue-100 text-blue-800'
                      }`}>
                        {alert.importance}
                      </span>
                      <span className='inline-block px-2 py-1 rounded text-xs bg-green-100 text-green-800'>
                        {alert.status}
                      </span>
                      <span className='inline-block px-2 py-1 rounded text-xs bg-blue-100 text-blue-800'>
                        {alert.alert_type}
                      </span>
                    </div>
                    <span className="text-sm text-gray-500">
                      {alert.due_at && `Due: ${new Date(alert.due_at).toLocaleDateString()}`}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
          {/* Pagination for Sent Alerts */}
          <div className="flex justify-center mt-4 space-x-2">
            {Array.from({ length: totalSentPages }, (_, i) => i + 1).map(page => (
              <button
                key={page}
                onClick={() => setSentPage(page)}
                className={`px-3 py-1 rounded ${
                  sentPage === page 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-gray-200 text-gray-700'
                }`}
              >
                {page}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Send Alert Section */}
      <div className='pl-4'>
        <h2 className='font-bold text-blue-700 text-lg pt-6 pb-2'>Send Alert</h2>
        
        {/* Department selection */}
        <div className="relative w-3/4 mb-4">
          <select
            name="department"
            value={assignedAlert.department}
            onChange={(e) => setAssignedAlert({ ...assignedAlert, department: e.target.value })}
            id="department"
            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          >
            <option value="" disabled hidden>Select a department</option>
            <option value="AD">Admin</option>
            <option value="BI">Billing</option>
            <option value="PC">Parent Coordination</option>
            <option value="PV">Providers</option>
            <option value="PR">PR</option>
            <option value="AL">All</option>
          </select>
          <label
            htmlFor="department"
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
          >
            To Who (Department)
          </label>
        </div>
        
        {/* Person selection */}
        <div className="relative w-3/4 mb-4">
          <select
            name="person"
            value={assignedAlert.alertTo}
            onChange={(e) => setAssignedAlert({ ...assignedAlert, alertTo: e.target.value })}
            id="person"
            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            disabled={!assignedAlert.department}
          >
            <option value="" disabled hidden>Select a person</option>
            {departmentPeople.map((person, index) => (
              <option key={index} value={person}>{person}</option>
            ))}
          </select>
          <label
            htmlFor="person"
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
          >
            Select Person
          </label>
        </div>

        {/* Alert Type selection */}
        <div className="relative w-3/4 mb-4">
          <select
            name="alertType"
            value={assignedAlert.alertType}
            onChange={(e) => setAssignedAlert({ ...assignedAlert, alertType: e.target.value })}
            id="alertType"
            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          >
            <option value="Announcement">Announcement</option>
            <option value="Reminder">Reminder</option>
            <option value="Task">Task</option>
            <option value="Update">Update</option>
            <option value="Alert">Alert</option>
          </select>
          <label
            htmlFor="alertType"
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
          >
            Alert Type
          </label>
        </div>
        
        {/* Importance selection */}
        <div className="relative w-3/4 mb-4">
          <select
            name="importance"
            value={assignedAlert.importance}
            onChange={(e) => setAssignedAlert({ ...assignedAlert, importance: e.target.value })}
            id="importance"
            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          >
            <option value="Low">Low</option>
            <option value="Medium">Medium</option>
            <option value="High">High</option>
            <option value="Urgent">Urgent</option>
          </select>
          <label
            htmlFor="importance"
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
          >
            Importance
          </label>
        </div>
        
        {/* Alert Description */}
        <div className="relative w-3/4 mb-4">
          <textarea 
            id="alert_desc" 
            placeholder="" 
            value={assignedAlert.description} 
            onChange={(e) => setAssignedAlert({ ...assignedAlert, description: e.target.value })} 
            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
          />
          <label 
            htmlFor="alert_desc" 
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
          >
            Alert Description
          </label>
        </div>
        
        {/* Due Date */}
        <div className="relative w-3/4 mb-4">
          <input 
            type='date' 
            value={assignedAlert.dueDate} 
            onChange={(e) => setAssignedAlert({ ...assignedAlert, dueDate: e.target.value })} 
            id="alert_due" 
            placeholder=" " 
            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
          />
          <label 
            htmlFor="alert_due" 
            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
          >
            Due Date
          </label>
        </div>

        <button 
          className="ml-1 border-2 p-2 border-blue-400 rounded-lg w-[10rem] bg-white" 
          onClick={handleSendAlert}
        >
          Send Alert
        </button>
      </div>
    </div>
  );
};

export default Alerts;