import React, { useEffect, useState, useMemo } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Text, Tooltip } from 'recharts';

// Color palette - can be easily updated or passed as props
const COLORS = {
  appointments: '#4F46E5', // Indigo
  meetings: '#7C3AED',     // Purple
  other: '#EC4899',        // Pink
  empty: '#E5E7EB'         // Light gray for empty state
};

const RADIAN = Math.PI / 180;

// Custom label renderer with improved positioning and visibility rules
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value }) => {
  // Don't render labels for empty or very small segments
  if (value === 0 || percent < 0.05) return null;
  
  const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <Text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
      fontWeight="bold"
      fontSize="12px"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </Text>
  );
};

// Improved legend component with better formatting
const CustomLegend = ({ data, total }) => {
  if (!data || data.length === 0) return null;

  return (
    <ul className="list-none p-0 mt-2 flex flex-col items-start w-full max-w-xs">
      {data.map((entry, index) => (
        <li key={`item-${index}`} className="mb-2 flex items-center w-full justify-between">
          <div className="flex items-center">
            <span
              className="w-3 h-3 rounded-full mr-2 inline-block"
              style={{ backgroundColor: entry.color }}
            />
            <span className="text-sm text-gray-700">{entry.name}</span>
          </div>
          <div className="flex items-center">
            <span className="font-medium text-sm">
              {entry.value > 0 ? `${((entry.value / total) * 100).toFixed(0)}%` : '0%'}
            </span>
            <span className="text-xs text-gray-500 ml-1">
              ({entry.value})
            </span>
          </div>
        </li>
      ))}
      
      {total > 0 && (
        <li className="mt-1 pt-2 border-t border-gray-200 w-full flex justify-between">
          <span className="text-sm font-medium text-gray-700">Total</span>
          <span className="text-sm font-medium">{total} events</span>
        </li>
      )}
    </ul>
  );
};

// Custom tooltip for interactive exploration
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="bg-white p-2 shadow-md rounded border border-gray-200 text-sm">
        <p className="font-medium">{data.name}</p>
        <p className="text-gray-700">{`Events: ${data.value}`}</p>
        <p className="text-gray-700">{`${(data.percent * 100).toFixed(1)}%`}</p>
      </div>
    );
  }
  return null;
};

const CircularChart = ({ events = [], title = "How Your Day Is Looking" }) => {
  // Process data with useMemo for performance
  const { chartData, total, completedPercentage } = useMemo(() => {
    if (!events || events.length === 0) {
      return { 
        chartData: [
          { id: 'empty', name: 'No Events', value: 1, color: COLORS.empty }
        ], 
        total: 0,
        completedPercentage: 0
      };
    }

    // Count events by category
    let appointments = 0;
    let meetings = 0;
    let other = 0;
    let completed = 0;

    events.forEach(event => {
      // Categorize the event
      const eventName = (event.event_name || '').toLowerCase();
      if (eventName.includes('appointment')) {
        appointments++;
      } else if (eventName.includes('meeting')) {
        meetings++;
      } else {
        other++;
      }

      // Check if event is completed (assuming there's a status field)
      if (event.event_status === 'Confirmed' || event.completed) {
        completed++;
      }
    });

    const total = appointments + meetings + other;
    
    // Calculate the percentage of completed events
    const completedPercentage = total > 0 ? Math.round((completed / total) * 100) : 0;

    // Create chart data with percentages
    const chartData = [
      { id: 'appointments', name: 'Appointments', value: appointments, color: COLORS.appointments, percent: total > 0 ? appointments / total : 0 },
      { id: 'meetings', name: 'Meetings', value: meetings, color: COLORS.meetings, percent: total > 0 ? meetings / total : 0 },
      { id: 'other', name: 'Other', value: other, color: COLORS.other, percent: total > 0 ? other / total : 0 }
    ].filter(item => item.value > 0); // Only include non-zero values

    // If all values are zero, show empty state
    if (chartData.length === 0) {
      chartData.push({ id: 'empty', name: 'No Events', value: 1, color: COLORS.empty });
    }

    return { chartData, total, completedPercentage };
  }, [events]);

  return (
    <div className="w-full flex flex-col items-center">
      <h2 className="text-xl font-bold mb-3 text-gray-800">{title}</h2>
      
      <div className="w-full max-w-xs relative mb-2">
        <ResponsiveContainer width="100%" height={220}>
          <PieChart>
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              innerRadius={50}
              dataKey="value"
              startAngle={90}
              endAngle={-270}
              paddingAngle={total > 0 ? 2 : 0}
            >
              {chartData.map((entry) => (
                <Cell key={`cell-${entry.id}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
        
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          {total > 0 ? (
            <>
              <div className="text-2xl font-bold text-gray-800">{completedPercentage}%</div>
              <div className="text-xs text-gray-500">Completed</div>
            </>
          ) : (
            <div className="text-sm font-medium text-gray-500">No Events</div>
          )}
        </div>
      </div>
      
      <CustomLegend data={chartData.filter(item => item.id !== 'empty')} total={total} />
    </div>
  );
};

export default CircularChart;