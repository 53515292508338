import Header from "./Header";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AddPatientPopUp from "./AddPatientPopUp";

const AllPatients = () => {
  const [patients, setPatients] = useState([]);
  const [originalPatients, setOriginalPatients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('Active');
  const navigate = useNavigate();
  const [autoPatientID, setAutoPatientID] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const HandleRemovePopUp = () => setOpenPopup(false);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [patientsPerPage] = useState(20);
  const [displayedPatients, setDisplayedPatients] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  const tabs = ['Pending', 'New', 'Active', 'Waiting List', 'Inactive'];

  const fetchPatients = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/listpatient');
  
      if (response.data) {
        // Parse and ensure data is an array
        const patientsData = Array.isArray(response.data)
          ? response.data
          : Array.isArray(response.data.body)
          ? response.data.body
          : JSON.parse(response.data.body);
  
        if (!Array.isArray(patientsData)) {
          console.error('API returned non-array data:', patientsData);
          setLoading(false);
          return;
        }

        console.log(`Total patients returned from API: ${patientsData.length}`);
  
        setOriginalPatients(patientsData);
  
        // Apply filters for active tab
        const activePatients = filterPatients(patientsData, '', activeTab);
        setPatients(activePatients);
        
        // Calculate total pages and update displayed patients
        updatePaginationForPatients(activePatients);
      } else {
        console.error('API response has no data');
      }
    } catch (error) {
      console.error('Error fetching patients:', error);
    } finally {
      setLoading(false);
    }
  };

  // Function to filter patients by status and search term
  const filterPatients = (patients, searchTerm, status) => {
    return patients.filter(
      (patient) =>
        patient.InactivePt === status &&
        (!searchTerm ||
          (patient.FirstName && patient.FirstName.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (patient.LastName && patient.LastName.toLowerCase().includes(searchTerm.toLowerCase())))
    );
  };

  // Update pagination based on filtered patients
  const updatePaginationForPatients = (filteredPatients, page = 1) => {
    const totalFilteredCount = filteredPatients.length;
    const calculatedTotalPages = Math.max(1, Math.ceil(totalFilteredCount / patientsPerPage));
    
    setTotalPages(calculatedTotalPages);
    
    // Make sure current page is valid for the new total
    const validPage = Math.min(page, calculatedTotalPages);
    setCurrentPage(validPage);
    
    // Get the patients for the current page
    const indexOfLastPatient = validPage * patientsPerPage;
    const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
    const currentPatients = filteredPatients.slice(indexOfFirstPatient, indexOfLastPatient);
    
    setDisplayedPatients(currentPatients);
  };

  const handleSearchChange = (e) => {
    setLoading(true);
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    
    // Filter across ALL patients by the search term
    const filteredResults = filterPatients(originalPatients, newSearchTerm, activeTab);
    setPatients(filteredResults);
    
    // Reset to page 1 for the new search results
    updatePaginationForPatients(filteredResults, 1);
    setLoading(false);
  };

  const handleTabChange = (tab) => {
    setLoading(true);
    setActiveTab(tab);
    
    // Filter across ALL patients by the selected tab
    const filteredResults = filterPatients(originalPatients, searchTerm, tab);
    setPatients(filteredResults);
    
    // Reset to page 1 for the new tab
    updatePaginationForPatients(filteredResults, 1);
    setLoading(false);
  };
  
  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    
    // Update displayed patients for the new page
    const indexOfLastPatient = newPage * patientsPerPage;
    const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
    const currentPatients = patients.slice(indexOfFirstPatient, indexOfLastPatient);
    
    setDisplayedPatients(currentPatients);
  };

  useEffect(() => {
    fetchPatients();
  }, []);

  const handlePatientClick = (patient) => {
    navigate(`/patients/${patient.PatientID}`, {state: { patient } });
  };

  const getMaxPatientID = (patients) => {
    if (patients.length === 0) return 0;
    const patientIDs = patients.map((patient) => parseInt(patient.PatientID));
    return Math.max(...patientIDs);
  };

  const openAddForm = () => {
    const maxPatientID = getMaxPatientID(originalPatients);
    const newPatientID = (maxPatientID + 1).toString();
    setAutoPatientID(newPatientID);
    setOpenPopup(true);
  };
  
  // Generate pagination buttons
  const renderPaginationButtons = () => {
    if (totalPages <= 1) return null;
    
    const buttons = [];
    
    // Previous button
    buttons.push(
      <button
        key="prev"
        onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
        disabled={currentPage === 1 || loading}
        className={`px-3 py-1 mx-1 rounded ${
          currentPage === 1 || loading
            ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
            : 'bg-blue-500 text-white hover:bg-blue-600'
        }`}
      >
        Previous
      </button>
    );
    
    // Page number buttons - show more buttons for better navigation
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, startPage + 4);
    
    // Adjust when near the end
    if (endPage - startPage < 4) {
      startPage = Math.max(1, endPage - 4);
    }
    
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          disabled={loading}
          className={`px-3 py-1 mx-1 rounded ${
            currentPage === i
              ? 'bg-blue-700 text-white'
              : loading
                ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          {i}
        </button>
      );
    }
    
    // Add ellipsis indicators
    if (startPage > 1) {
      buttons.splice(1, 0, <span key="start-ellipsis" className="px-2">...</span>);
    }
    
    if (endPage < totalPages) {
      buttons.push(<span key="end-ellipsis" className="px-2">...</span>);
      buttons.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          disabled={loading}
          className="px-3 py-1 mx-1 rounded bg-blue-500 text-white hover:bg-blue-600"
        >
          {totalPages}
        </button>
      );
    }
    
    // Next button
    buttons.push(
      <button
        key="next"
        onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
        disabled={currentPage === totalPages || loading}
        className={`px-3 py-1 mx-1 rounded ${
          currentPage === totalPages || loading
            ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
            : 'bg-blue-500 text-white hover:bg-blue-600'
        }`}
      >
        Next
      </button>
    );
    
    return buttons;
  };

  return (
    <div className="py-16 flex flex-col relative h-screen">
      <Header />
      <h1 className="text-center">Patients Database</h1>
      <div className="flex flex-col justify-center">
        <div className="flex justify-center mb-4">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={openAddForm}
          >
            Add Patient
          </button>
        </div>
        <AddPatientPopUp 
          openPopUp={openPopup} 
          closePopUp={HandleRemovePopUp} 
          fetchPatients={fetchPatients}
          autoPatientID={autoPatientID}
          patients={originalPatients}
        />

        <div className="flex w-full h-12 rounded-lg bg-white overflow-hidden mb-4">
          <div className="grid place-items-center h-full w-12 text-gray-300">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search patients..."
          />
        </div>

        <div className="flex justify-center mb-4">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`px-4 py-2 mr-2 rounded ${
                activeTab === tab
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
              onClick={() => handleTabChange(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className="mb-2 text-sm text-gray-600 flex justify-between">
          <span>
            {loading ? (
              "Loading patients..."
            ) : (
              `Showing ${displayedPatients.length} of ${patients.length} patients`
            )}
          </span>
          {searchTerm && (
            <span className="text-blue-600">
              Searching across all patients for "{searchTerm}"
            </span>
          )}
        </div>

        {loading ? (
          <div className="flex justify-center my-8">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          <>
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">First Name</th>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">Last Name</th>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">Email</th>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">Status</th>
                </tr>
              </thead>
              <tbody>
                {displayedPatients.length > 0 ? (
                  displayedPatients.map((patient) => (
                    <tr key={patient.PatientID} onClick={() => handlePatientClick(patient)} className="cursor-pointer hover:bg-gray-100">
                      <td className="font-normal p-4">{patient.FirstName}</td>
                      <td className="font-normal p-4">{patient.LastName}</td>
                      <td className="font-normal p-4">{patient.Email}</td>
                      <td className="font-normal p-4">{patient.InactivePt}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center p-4">
                      {searchTerm ? `No patients found matching "${searchTerm}"` : `No ${activeTab.toLowerCase()} patients found`}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            
            {/* Pagination controls */}
            {displayedPatients.length > 0 && (
              <div className="flex justify-center mt-4 mb-8">
                {renderPaginationButtons()}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AllPatients;