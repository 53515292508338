import React, { useState } from 'react';
import { Search, FileText } from 'lucide-react';
import Header from "./Header";
import axios from 'axios';

const Card = ({ children, className = '' }) => (
  <div className={`bg-white rounded-lg shadow-sm ${className}`}>
    {children}
  </div>
);

const Reports = () => {
  // State management
  const [selectedDepartment, setSelectedDepartment] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [activeFilter, setActiveFilter] = useState('all'); // 'all', 'active', 'inactive'
  const [showPlatformReport, setShowPlatformReport] = useState(null);

  // Sample departments and reports data
  const departments = [
    { id: 'front', name: 'FRONT DESK', items: [
      'Master File',
      'Appointment Attendance',
      'Reservation',
      'Room Operation',
      'Patients',
    ]},
    { id: 'billing', name: 'BILLING', items: [
      'Encounters',
      'Charges'
    ]},
    { id: 'admin', name: 'ADMIN', items: [
      'Manage Platform',
      'Staff',
      'Settings'
    ]}
  ];

  const reports = [
    {
      id: 1,
      title: 'Tebra Encounters',
      department: 'billing',
      status: 'active',
      metrics: [
        { label: 'Total Created', value: '1,384', color: 'bg-emerald-600' },
        { label: 'Successful Encounters', value: '1,384', color: 'bg-blue-500' },
        { label: 'Placehodler', value: '$50,000', color: 'bg-purple-500' },
        { label: 'Placeholder', value: '612,120', color: 'bg-red-500' }
      ]
    },
    {
      id: 2,
      title: 'Master File',
      department: 'front',
      status: 'active',
      metrics: [
        { label: 'Total Patients', value: '1,252', color: 'bg-emerald-600' },
        { label: 'Unknown', value: '1', color: 'bg-blue-500' },
        { label: 'Placehodler', value: '0', color: 'bg-purple-500' },
        { label: 'Placeholder', value: '14,760', color: 'bg-red-500' }
      ]
    },
    {
      id: 3,
      title: 'Appointment Attendance',
      department: 'front',
      status: 'active',
      metrics: [
        { label: 'Total Scheduled', value: '371', color: 'bg-emerald-600' },
        { label: 'Confirmed Bookings', value: '312', color: 'bg-blue-500' },
        { label: 'Canceled Bookings', value: '45', color: 'bg-purple-500' },
        { label: 'Unknown Status', value: '11', color: 'bg-red-500' }
      ]
    },
    {
        id: 4,
        title: 'Charges',
        department: 'billing',
        status: 'active',
        metrics: [
          { label: 'Total Appointments Charged', value: '371', color: 'bg-emerald-600' },
          { label: 'Successful Charges', value: '368', color: 'bg-blue-500' },
          { label: 'Unsuccessful Charges', value: '3', color: 'bg-purple-500' },
          { label: 'Total Charge Value', value: '$99999', color: 'bg-red-500' }
        ]
      },
      {
        id: 5,
        title: 'Children With NO Photo Consent',
        department: 'front',
        status: 'active',
        metrics: [
          { label: 'Total Active Patients', value: '3119', color: 'bg-emerald-600' },
          { label: 'Total With NO Photo Consent', value: '2853', color: 'bg-blue-500' },
          { label: 'Total WITH Photo Consent', value: '266', color: 'bg-purple-500' },
          { label: 'Placeholder', value: '$99999', color: 'bg-red-500' }
        ]
      }
  ];

  const generateMasterFileExport = async () => {
    try {
      console.log('Initiating master file export');
  
      const response = await axios.post(
        'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/master_file_export'
      );
      
      console.log('Full response:', response);
      
      // The API response structure is different than expected
      // The actual data is in response.data, and it might be a string that needs parsing
      let responseData = response.data;
      
      // If the responseData is a string, try to parse it
      if (typeof responseData === 'string') {
        try {
          responseData = JSON.parse(responseData);
        } catch (e) {
          console.error('Failed to parse response string:', e);
        }
      }
      
      // If the data has a 'body' property that's a string, parse it too
      if (responseData && typeof responseData.body === 'string') {
        try {
          responseData.body = JSON.parse(responseData.body);
        } catch (e) {
          console.error('Failed to parse response body string:', e);
        }
      }
      
      console.log('Processed response data:', responseData);
      
      // Extract download URL - check different possible locations
      let downloadUrl = '';
      let filename = 'master_file_export.csv';
      let totalRecords = 0;
      
      // Try to find download URL in different possible locations
      if (responseData?.body?.download_url) {
        // If the URL is in response.data.body.download_url
        downloadUrl = responseData.body.download_url;
        filename = responseData.body.filename || filename;
        totalRecords = responseData.body.total_records || 0;
      } else if (responseData?.download_url) {
        // If the URL is directly in response.data.download_url
        downloadUrl = responseData.download_url;
        filename = responseData.filename || filename;
        totalRecords = responseData.total_records || 0;
      }
      
      if (!downloadUrl) {
        throw new Error('No download URL generated');
      }
  
      console.log('Download URL found:', downloadUrl);
      
      // Create a temporary link to trigger download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      link.parentNode.removeChild(link);
      
      alert(`Export generated: ${filename} with ${totalRecords} records`);
    } catch (error) {
      console.error('Full error:', error);
      
      // More detailed error logging
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        alert(`Export failed: ${error.response.data?.error || 'Unknown error'}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        alert('Export failed: No response from server');
      } else {
        console.error('Error message:', error.message);
        alert(`Export failed: ${error.message}`);
      }
    }
  };

  // Calculate platform counts
  const platformCounts = {
    all: reports.length,
    active: reports.filter(r => r.status === 'active').length,
    inactive: reports.filter(r => r.status === 'inactive').length
  };

  // Filter reports based on all criteria
  const filteredReports = reports.filter(report => {
    const matchesDepartment = selectedDepartment === 'all' || report.department === selectedDepartment;
    const matchesSearch = report.title.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesStatus = activeFilter === 'all' || report.status === activeFilter;
    return matchesDepartment && matchesSearch && matchesStatus;
  });

  // Handle department selection
  const handleDepartmentClick = (deptId) => {
    setSelectedDepartment(deptId);
  };

  // Platform Report Modal
  const PlatformReportModal = ({ report, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-2xl w-full m-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">Platform Report: {report.title}</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            ×
          </button>
        </div>
        <div className="space-y-4">
          {report.metrics.map((metric, idx) => (
            <div key={idx} className="flex justify-between border-b pb-2">
              <span>{metric.label}:</span>
              <span className="font-semibold">{metric.value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50 mt-16">
      <Header />
      
      <div className="flex">
        {/* Sidebar */}
        <div className="w-64 bg-white p-4 min-h-screen border-r">
          {departments.map(dept => (
            <div key={dept.id} className="mb-6">
              <h3 className="text-xs text-gray-500 font-semibold mb-2">{dept.name}</h3>
              <ul className="space-y-2">
                {dept.items.map(item => (
                  <li 
                    key={item}
                    onClick={() => handleDepartmentClick(dept.id)}
                    className={`text-sm text-gray-700 hover:bg-gray-100 p-2 rounded cursor-pointer transition-colors
                      ${selectedDepartment === dept.id ? 'bg-gray-100' : ''}`}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Main Content Area */}
        <div className="flex-1 p-6">
          <div className="mb-8">
            <div className="flex items-center justify-between mb-6">
              <h1 className="text-xl font-semibold">Manage Reports</h1>
              <span className="text-sm text-gray-500">The Sensory Studio</span>
            </div>
            
            {/* Search and Filter Bar */}
            <div className="flex items-center justify-between mb-6">
              <div className="relative">
                <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
                <input
                  type="text"
                  placeholder="Search..."
                  className="pl-10 pr-4 py-2 border rounded-md w-64 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              
              <button className="px-4 py-2 bg-white border rounded-md text-sm hover:bg-gray-50 transition-colors">
                Export
              </button>
            </div>

            {/* Platform Filter Pills */}
            <div className="flex gap-2 mb-6">
              <button 
                onClick={() => setActiveFilter('all')}
                className={`px-4 py-1.5 text-sm rounded-full transition-colors
                  ${activeFilter === 'all' 
                    ? 'bg-emerald-700 text-white' 
                    : 'bg-white text-gray-700 border'}`}
              >
                All Reports ({platformCounts.all})
              </button>
              <button 
                onClick={() => setActiveFilter('active')}
                className={`px-4 py-1.5 text-sm rounded-full transition-colors
                  ${activeFilter === 'active' 
                    ? 'bg-emerald-700 text-white' 
                    : 'bg-white text-gray-700 border'}`}
              >
                Active Reports ({platformCounts.active})
              </button>
              <button 
                onClick={() => setActiveFilter('inactive')}
                className={`px-4 py-1.5 text-sm rounded-full transition-colors
                  ${activeFilter === 'inactive' 
                    ? 'bg-emerald-700 text-white' 
                    : 'bg-white text-gray-700 border'}`}
              >
                Inactive Reports ({platformCounts.inactive})
              </button>
            </div>

            {/* Reports Grid */}
            <div className="space-y-4">
              {filteredReports.map(report => (
                <Card key={report.id} className="p-6">
                  <div className="flex items-center justify-between mb-6">
                    <div className="flex items-center gap-3">
                      <div className="w-10 h-10 bg-blue-600 rounded-full flex items-center justify-center text-white font-semibold">
                        {report.title[0]}
                      </div>
                      <div>
                        <h3 className="font-semibold">{report.title}</h3>
                        <p className="text-sm text-gray-500">#{report.id}</p>
                      </div>
                    </div>
                    <div className="flex items-center gap-4">
                      <span className={`text-sm px-3 py-1 rounded-full
                        ${report.status === 'active' 
                          ? 'text-green-700 bg-green-100' 
                          : 'text-gray-700 bg-gray-100'}`}
                      >
                        {report.status === 'active' ? 'Active Report' : 'Inactive Report'}
                      </span>
                      <button 
                        onClick={() => setShowPlatformReport(report)}
                        className="text-sm text-gray-600 hover:text-gray-800 flex items-center gap-2"
                      >
                        <FileText className="w-4 h-4" />
                        Access Report
                      </button>
                      {report.title === 'Master File' && (
                        <button 
                          onClick={generateMasterFileExport}
                          className="text-sm text-blue-600 hover:text-blue-800 flex items-center gap-2 border border-blue-300 rounded px-2 py-1 hover:bg-blue-50"
                        >
                          <FileText className="w-4 h-4" />
                          Export File
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-4 gap-4">
                    {report.metrics.map((metric, idx) => (
                      <div key={idx} className="p-4 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors">
                        <p className="text-sm text-gray-600 mb-2">{metric.label}</p>
                        <p className="text-lg font-semibold">{metric.value}</p>
                      </div>
                    ))}
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Platform Report Modal */}
      {showPlatformReport && (
        <PlatformReportModal 
          report={showPlatformReport} 
          onClose={() => setShowPlatformReport(null)} 
        />
      )}
    </div>
  );
};

export default Reports;