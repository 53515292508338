import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'; 
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarDaysIcon, XMarkIcon } from '@heroicons/react/24/outline';


function CreateEventModal({ isOpen, onClose }) {
    const [patients, setPatients] = useState([]);
    const [originalPatients, setOriginalPatients] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearchFocused, setIsSearchFocused] = useState(false);
    const [selectedProvID, setSelectedProvId] = useState(null);
    const initialFormState = {
        event_name: '',
        event_description: '',
        provider_id: '',
        patient_id: '',
        location: '',
        service: '',
        start_time: '',
        end_time: '',
        end_date: '',
        repeat_interval: '',
    };
    const [formData, setFormData] = useState(initialFormState);
    const [provider, setProvider] = useState('');
    const [getProviders, setGetProviders] = useState([]);
    const [selectedPatientId, setSelectedPatientId] = useState('');
    const [isMultiday, setIsMultiday] = useState(false);
    const [recurrenceType, setRecurrenceType] = useState('once');
    const [selectedDays, setSelectedDays] = useState({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
    });
    const [isPatientTableOpen, setIsPatientTableOpen] = useState(false);
    const searchInputRef = useRef(null);
    const patientTableRef = useRef(null);
    const [selectedPatientName, setSelectedPatientName] = useState('');

    

    const handleRecurrenceTypeChange = (type) => {
      setRecurrenceType(type);
      if (type === 'once') {
        const startDate = formData.start_time ? new Date(formData.start_time) : null;

        setFormData(prevState => ({
          ...prevState,
          end_date: startDate,
          repeat_interval: null
        }));
      } else if (type === 'weekly') {
        setFormData(prevState => ({
          ...prevState,
          repeat_interval: '604800'
        }));
      } else if (type === 'multiday') {
        setFormData(prevState => ({
          ...prevState,
          repeat_interval: '604800'
        }));
      }
      if (type !== 'multiday') {
        setSelectedDays({
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
        });
      }
    };

    const handleDaySelect = (day) => {
      setSelectedDays(prevDays => ({
        ...prevDays,
        [day]: !prevDays[day],
      }));
    };

    const handlePatientSelect = (patientId, patientFirst, patientLast) => {
      setSelectedPatientId(patientId);
      setIsSearchFocused(false); // Close the search results
      setSearchTerm(''); // Clear the search term
      const fullName = patientFirst + ' ' + patientLast;
      setSelectedPatientName(fullName)

      setFormData(prevState => ({
        ...prevState,
        patient_id: patientId
      }));

      console.log('Patient selected: ', patientId);
    };

    const autoProviders = async (e) => {
      if (e) e.preventDefault();
    
      try {
        const params = new URLSearchParams();
    
        const response = await axios.get(`https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_providers?${params.toString()}`);
    
        let providersArray = [];
    
        if (typeof response.data === 'string') {
          // Custom parsing for the specific string format
          const match = response.data.match(/body=(\[.*?\])/s);
          if (match && match[1]) {
            try {
              const bodyContent = match[1].replace(/'/g, '"'); // Replace single quotes with double quotes
              const parsedBody = JSON.parse(bodyContent);
              if (Array.isArray(parsedBody)) {
                providersArray = parsedBody.map(provider => ({
                  id: provider.pr_id,
                  name: provider.pr_name
                }));
              }
            } catch (parseError) {
              console.error('Error parsing body content:', parseError);
            }
          } else {
            console.error('Unable to extract body content from response');
          }
        } else if (typeof response.data === 'object' && response.data !== null) {
          // Handle case where response.data is already an object
          if (response.data.body && Array.isArray(response.data.body)) {
            providersArray = response.data.body.map(provider => provider.pr_name);
          } else {
            console.error('Unexpected data structure:', response.data);
          }
        } else {
          console.error('Unexpected response type:', typeof response.data);
        }
    
        setGetProviders(providersArray);
      } catch (error) {
        console.error('Error calling Lambda function:', error);
        setGetProviders([]);
      }
    };

    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      if (type === 'checkbox') {
        if (name === 'repeat_interval') {
          setFormData(prevState => ({
            ...prevState,
            repeat_interval: value === 'weekly' ? '604800' : null
          }));
          setIsMultiday(false);
          setSelectedDays({
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false
          });
        } else if (['monday', 'tuesday', 'wednesday', 'thursday', 'friday'].includes(name)) {
          setSelectedDays(prevDays => ({
            ...prevDays,
            [name]: checked
          }));
          setIsMultiday(true);
          setFormData(prevState => ({
            ...prevState,
            repeat_interval: '604800'  // Set to weekly for multiday
          }));
        } else {
          setFormData(prevState => ({...prevState, [name]: checked }));
        }
      } else {
        setFormData(prevState => ({...prevState, [name]: value }));
      }
  
      // If the service field is changed, update the event_name
      if (name === 'service') {
        const upperValue = value.trim().toUpperCase();
        if (upperValue === 'ST') {
          setFormData(prevState => ({
            ...prevState,
            event_name: 'ST Appointment'
          }));
        } else if (upperValue === 'OT') {
          setFormData(prevState => ({
            ...prevState,
            event_name: 'OT Appointment'
          }));
        } else {
          setFormData(prevState => ({
            ...prevState,
            event_name: ''
          }));
        }
      }
    };

    const handleRepeatIntervalChange = (value) => {
      setFormData(prevData => ({
        ...prevData,
        repeat_interval: value
      }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const formatDateTime = (dateTimeString) => {
          const date = new Date(dateTimeString);
          return date.toISOString().slice(0, 19).replace('T', ' ');
        };
    
        const dataToSend = {
          ...formData,
          provider_id: parseInt(formData.provider_id),
          patient_id: parseInt(formData.patient_id),
          location: parseInt(formData.location),
          start_time: formatDateTime(formData.start_time),
          end_time: formatDateTime(formData.end_time),
          repeat_interval: parseInt(formData.repeat_interval)
        };

        if (recurrenceType === 'multiday') {
          dataToSend.monday = selectedDays.monday;
          dataToSend.tuesday = selectedDays.tuesday;
          dataToSend.wednesday = selectedDays.wednesday;
          dataToSend.thursday = selectedDays.thursday;
          dataToSend.friday = selectedDays.friday;
        }

        const apiUrl = (recurrenceType === 'multiday') 
    ? 'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/create_event_multiday'
    : 'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/create_event';

        console.log('Data being sent to create_event API:', JSON.stringify(dataToSend, null, 2));
        console.log('Api URL:', apiUrl);
        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend)
          });
    
          const result = await response.json();
          console.log("Result:", result);
    
          if (result.statusCode === 200) {
            setFormData(initialFormState);
            setProvider('');
            console.log('Form Data: ', formData);
            console.log('Success:', result);
            alert("Appointment Created Successfully!");
            onClose(); // Close the modal on success
          } else if (result.statusCode === 500) {
            let errorMessage = result.body;
            if (typeof errorMessage === 'string') {
              try {
                const errorBody = JSON.parse(errorMessage);
                errorMessage = errorBody.error || errorMessage;
              } catch (e) {
                // If parsing fails, use the original message
              }
            }
            const contextIndex = errorMessage.indexOf("CONTEXT");
            if (contextIndex !== -1) {
              errorMessage = errorMessage.substring(0, contextIndex).trim();
            }
            alert(`Appointment Failed to Create: ${errorMessage}`);
          } else {
            alert(`Appointment Failed to Create: Unexpected error`);
          }
        } catch (error) {
          console.error('Error:', error);
          alert(`Failed to create appointment: ${error.message}`);
        }
    };

    const fetchPatients = async () => {
        try {
          const response = await axios.get('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/listpatient');
          
          let patientsData = [];
          if (response.data) {
            if (Array.isArray(response.data)) {
              patientsData = response.data;
            } else if (typeof response.data === 'string') {
              patientsData = JSON.parse(response.data);
            } else if (response.data.body) {
              patientsData = JSON.parse(response.data.body);
            }
          }
      
          if (!Array.isArray(patientsData)) {
            console.error('Patients data is not an array:', patientsData);
            patientsData = []; // Fallback to empty array
          }
      
          setPatients(patientsData);
          setOriginalPatients(patientsData);
        } catch (error) {
          console.error('Error fetching patients:', error);
          setPatients([]);
          setOriginalPatients([]);
        }
    };

    const filterPatients = (patients, searchTerm) => {
        if (!Array.isArray(patients)) {
          console.error('Patients is not an array:', patients);
          return [];
        }
        if (!searchTerm) return patients;
        
        return patients.filter(
          (patient) =>
            (patient.FirstName && patient.FirstName.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (patient.LastName && patient.LastName.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    };

    const handleSearchChange = (e) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
      
        // Filter patients based on the search term
        const filteredResults = filterPatients(originalPatients, newSearchTerm);
        setPatients(filteredResults);
    };

    const handleSearchFocus = () => {
      setIsSearchFocused(true);
      setIsPatientTableOpen(true);
    }

    const handleClickOutside = (event) => {
      if (searchInputRef.current && !searchInputRef.current.contains(event.target) &&
          patientTableRef.current && !patientTableRef.current.contains(event.target)) {
          setIsPatientTableOpen(false);
      }
    };

    const clearSearch = () => {
      setSearchTerm('');
      setPatients(originalPatients);
      setIsPatientTableOpen(false);
      searchInputRef.current?.blur();
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
      if (recurrenceType === 'once' && formData.start_time) {
        setFormData(prevState => ({
          ...prevState,
          end_date: new Date(formData.start_time)
        }));
      }
    }, [formData.start_time, recurrenceType]);

    useEffect(() => {
        fetchPatients();
        autoProviders();
    }, []);

  if (!isOpen) return null;

  return (
    <>
      <div className="mt-8 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative mx-auto w-full max-w-[800px] max-h-[90vh] flex flex-col">
          <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="sticky top-0 z-50 bg-white flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-2xl font-semibold">Create Appointment</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={onClose}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <div className="relative p-6 flex-auto overflow-y-auto" style={{ maxHeight: 'calc(90vh - 200px)' }}>
              <div className="flex flex-col relative">
                <div className="flex flex-col justify-center">
                  <div className="pl-2 flex w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden">
                    <div className="grid place-items-center h-full w-12 text-gray-300">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </div>
        <input
          ref={searchInputRef}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          onFocus={handleSearchFocus}
          placeholder="Search patients..."
        />
        
    </div>
    {isPatientTableOpen && (
    <div ref={patientTableRef} className="h-[250px] overflow-y-auto border border-gray-200 rounded-lg">
      <table className="w-full min-w-max table-auto text-left">
        <thead className="sticky top-0 bg-white">
          <tr>
            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">First Name</th>
            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">Last Name</th>
            <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">Patient ID</th>
          </tr>
        </thead>
        <tbody className="">
          {patients.map((patient) => (
            <tr 
            key={patient.PatientID}
            onClick={(e) => {
              e.preventDefault();
              console.log('Row clicked:', patient.PatientID);
              handlePatientSelect(patient.PatientID, patient.FirstName, patient.LastName);
              setIsPatientTableOpen(false);
            }}
              className="cursor-pointer hover:bg-gray-100 h-[50px]"
            >
              <td className="font-normal p-4">{patient.FirstName}</td>
              <td className="font-normal p-4">{patient.LastName}</td>
              <td className="font-normal p-4">{patient.PatientID}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      )}
      </div>
    </div>
            </div>
            
            <div className="relative w-3/4 ml-10 mb-4">
              <select
                name="provider_id"
                value={provider}
                onChange={(e) => {
                  const selectedProvider = getProviders.find(p => p.name === e.target.value);
                  setProvider(e.target.value);
                  const newProviderId = selectedProvider ? selectedProvider.id : null;
                  setSelectedProvId(newProviderId);

                  setFormData(prevState => ({
                    ...prevState,
                    provider_id: newProviderId,
                  }));
                }}
                disabled=''
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              >
                <option value="" disabled hidden>None</option>
                {getProviders.length > 0 ? (
                    getProviders.map((provider) => (
                      <option key={provider.id} value={provider.name}>
                        {provider.name}
                      </option>
                    ))
                  ) : (
                    <option>No providers available</option>
                  )}
              </select>
              <label className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                Provider
              </label>
            </div>
            <div class="relative w-3/4 ml-10 mb-4">
              <input name="patient_id" type="text" value={selectedPatientName} onChange={handleChange} id="patient_id" placeholder=" " required class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
              <label for="patient_id" class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Patient Name</label>
            </div>
            <div className="relative w-3/4 ml-10 mb-4">
              <select
                name="service"
                value={formData.service}
                onChange={handleChange}
                id="service"
                required
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              >
                <option value="" disabled hidden>Select a service/reason</option>
                <option value="ST">ST Session</option>
                <option value="OT">OT Session</option>
                <option value="STI">ST Intake</option>
                <option value="OTI">OT Intake</option>
                <option value="SOTI">ST & OT Intake</option>
                <option value="RSTI">Rescheduled ST Intake</option>
                <option value="ROTI">Rescheduled OT Intake</option>
                <option value="PG">Peer Group Session</option>
                <option value="ME">Meeting</option>
                <option value="BK">Break</option>
                <option value="NO">Notes</option>
                <option value="LU">Lunch</option>
                <option value="TO">Time Off</option>
                <option value="MISC">Other</option>
              </select>
              <label
                htmlFor="service"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                What is this appointment for?
              </label>
            </div>
            <div class="relative w-3/4 ml-10 mb-4">
              <input name="event_name" type="text" value={formData.event_name} onChange={handleChange} id="event_name" placeholder=" " required class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
              <label for="event_name" class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Event Name</label>
            </div>
            {/* <div class="relative w-3/4 ml-10 mb-4">
              <input name="event_description" type="text" value={formData.event_description} onChange={handleChange} id="event_description" placeholder=" " required class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
              <label for="event_description" class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Event Description</label>
                  </div> */}
            <div className="relative w-3/4 ml-10 mb-4">
              <select
                name="location"
                value={formData.location}
                onChange={handleChange}
                id="location"
                required
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              >
                <option value="" disabled hidden>Select a location</option>
                <option value="8">8</option>
                <option value="225">225</option>
                <option value="445">445</option>
              </select>
              <label
                htmlFor="location"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Location
              </label>
            </div>
            {/* <div className="relative w-3/4 ml-10 mb-4">
              <input 
                name="start_time" 
                type="datetime-local" 
                value={formData.start_time} 
                onChange={handleChange} 
                id="start_time" 
                placeholder=" " 
                required 
                step="1800"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
              />
              <label 
                htmlFor="start_time" 
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Session Start Time
              </label>
            </div> */}
            <div className="relative w-3/4 ml-10 mb-4">
              <DatePicker
                  name="start_time"
                  id="start_time"
                  selected={formData.start_time ? new Date(formData.start_time) : null}
                  onChange={(date) => handleChange({ target: { name: 'start_time', value: date } })}
                  showTimeSelect
                  timeIntervals={15}
                  timeFormat="hh:mm aa"
                  timeCaption="Time"
                  dateFormat="MMMM d, yyyy hh:mm aa"
                  className="block px-2.5 pb-2.5 pt-4 w-[37.5rem] text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer pr-10"
                  minTime={new Date(0, 0, 0, 8, 0)} // 8:00 AM
                  maxTime={new Date(0, 0, 0, 19, 30)} // 6:00 PM
                  placeholderText="Select date and time"
                  isClearable
                  popperPlacement="top"
                />
              <CalendarDaysIcon className={`absolute top-1/2 transform -translate-y-1/2 w-5 h-5 text-blue-600 transition-all duration-200 ${formData.start_time ? 'right-8' : 'right-3'}`} />
              <label 
                htmlFor="start_time" 
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Session Start Time
              </label>
            </div>
            {/* <div className="relative w-3/4 ml-10 mb-4">
              <input 
                name="end_time" 
                type="datetime-local" 
                value={formData.end_time} 
                onChange={handleChange} 
                id="end_time" 
                placeholder=" " 
                required 
                step="1800"
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
              />
              <label 
                htmlFor="end_time" 
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Session End Time
              </label>
            </div> */}
            <div className="relative w-3/4 ml-10 mb-4">
              <DatePicker
                  name="end_time"
                  id="end_time"
                  selected={formData.end_time ? new Date(formData.end_time) : null}
                  onChange={(date) => handleChange({ target: { name: 'end_time', value: date } })}
                  showTimeSelect
                  timeIntervals={15}
                  timeFormat="hh:mm aa"
                  timeCaption="Time"
                  dateFormat="MMMM d, yyyy hh:mm aa"
                  className="block px-2.5 pb-2.5 pt-4 w-[37.5rem] text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer pr-10"
                  minTime={new Date(0, 0, 0, 8, 0)} // 8:00 AM
                  maxTime={new Date(0, 0, 0, 19, 30)} // 6:00 PM
                  placeholderText="Select date and time"
                  isClearable
                  popperPlacement="top"
                />
              <CalendarDaysIcon className={`absolute top-1/2 transform -translate-y-1/2 w-5 h-5 text-blue-600 transition-all duration-200 ${formData.end_time ? 'right-8' : 'right-3'}`} />
              <label 
                htmlFor="end_time" 
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Session End Time
              </label>
            </div>
            {/* <div class="relative w-3/4 ml-10 mb-4">
              <input name="end_date" type="date" value={formData.end_date} onChange={handleChange} id="end_date" placeholder=" " required class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
              <label for="end_date" class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Last Appointment Reccurance</label>
          </div> */}
          <div className="relative w-3/4 ml-10 mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">Recurrence</label>
          <div className="grid sm:grid-cols-3 gap-2">
            <label for="recurrenceType" className="flex p-3 w-full bg-white border border-gray-300 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400">
              <input
                type="radio"
                name="recurrenceType"
                value="once"
                checked={recurrenceType === 'once'}
                onChange={() => handleRecurrenceTypeChange('once')}
                className="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                id="recurrenceType"
              />
              <span className="text-sm text-gray-900 ms-3 dark:text-neutral-400">Single Appt</span>
            </label>
            <label for="recurrenceType" className="flex p-3 w-full bg-white border border-gray-300 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400">
              <input
                type="radio"
                name="recurrenceType"
                value="weekly"
                checked={recurrenceType === 'weekly'}
                onChange={() => handleRecurrenceTypeChange('weekly')}
                className="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                id="recurrenceType"
              />
              <span className="text-sm text-gray-900 ms-3 dark:text-neutral-400">Once Per Week</span>
            </label>
            <label for="recurrenceType" className="flex p-3 w-full bg-white border border-gray-300 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400">
              <input
                type="radio"
                name="recurrenceType"
                value="multiday"
                checked={recurrenceType === 'multiday'}
                onChange={() => handleRecurrenceTypeChange('multiday')}
                className="shrink-0 mt-0.5 border-gray-200 rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800"
                id="recurrenceType"
              />
              <span className="text-sm text-gray-900 ms-3 dark:text-neutral-400">Multiday</span>
            </label>
          </div>

            {recurrenceType === 'multiday' && (
              <div className="flex items-center space-x-4 mt-2">
                {['monday', 'tuesday', 'wednesday', 'thursday', 'friday'].map((day) => (
                  <label key={day} className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={selectedDays[day]}
                      onChange={() => handleDaySelect(day)}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span className="ml-2 text-gray-700 capitalize">{day}</span>
                  </label>
                ))}
              </div>
            )}
          </div>

          <div className="relative w-3/4 ml-10 mb-4">
            {recurrenceType !== 'once' && (
              <>
                <DatePicker
                  name="end_date"
                  id="end_date"
                  selected={formData.end_date ? new Date(formData.end_date) : null}
                  onChange={(date) => handleChange({ target: { name: 'end_date', value: date } })}
                  dateFormat="MMMM d, yyyy"
                  className="block px-2.5 pb-2.5 pt-4 w-[37.5rem] text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer pr-10"
                  placeholderText="Select date"
                  isClearable
                />
                <CalendarDaysIcon className={`absolute top-1/2 transform -translate-y-1/2 w-5 h-5 text-blue-600 transition-all duration-200 ${formData.end_date ? 'right-8' : 'right-3'}`} />
                <label 
                  htmlFor="end_date" 
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                >
                  Last Appointment Recurrence
                </label>
              </>
            )}
          </div>

            <div className="sticky bottom-0 bg-white z-50 flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
            <button
                className="bg-white text-black border-2 active:bg-red-600 px-6 h-11 rounded-3xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type=""
                onClick={(e) => {
                    onClose();
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
              </button>
              <button
                className="bg-blue-500 border-2 text-white active:bg-blue-600 uppercase text-sm px-6 py-3 rounded-3xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="submit"
                onClick={(e) => {
                    handleSubmit(e);
                }}
              >
                Create Event
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default CreateEventModal;