import Header from "./Header";
import Calendar from "./Calendar";
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import { format, addMonths } from 'date-fns';
import axios from "axios";
import { useUser } from './UserContext';
import { ClockIcon, MapPinIcon, PencilIcon, CommandLineIcon } from '@heroicons/react/24/outline';
import { UserIcon } from '@heroicons/react/24/solid';

import PieChart from "./PieChart";

const HomePage = ({ user, signOut }) => {

  const [events, setEvents] = useState([]);
  const [patients, setPatients] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addMonths(new Date(), 1));
  const [provider, setProvider] = useState('');
  const [getProviders, setGetProviders] = useState([]);
  const [selectedProvID, setSelectedProvId] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const { userName, userRole } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [userMatchesProvider, setUserMatchesProvider] = useState(false);
  const [selectedCalendarDate, setSelectedCalendarDate] = useState(new Date());
  const navigate = useNavigate();
  const [eventNotes, setEventNotes] = useState({});
  const [patientInfo, setPatientInfo] = useState({});
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [providerColors, setProviderColors] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const sortEventsByDateTime = (events) => {
    return [...events].sort((a, b) => {
      const dateA = parseDate(a.start_time);
      const dateB = parseDate(b.start_time);
      if (!dateA || !dateB) return 0;
      
      // First sort by date
      const dateCompare = dateA - dateB;
      if (dateCompare !== 0) return dateCompare;
      
      // If same date, sort by provider name
      return (a.providerName || '').localeCompare(b.providerName || '');
    });
  };

  /* const renderEvents = () => {
    return mappedEvents.map((providerData, index) => (
      providerData.events.map((event) => (
        <div 
          key={event.id} 
          style={{ backgroundColor: providerColors[index % providerColors.length] }}
          className="event-block"
        >
          {event.name}
        </div>
      ))
    ));
  }; */

  const fetchProviderEvents = async (providerIds, date = selectedCalendarDate) => {
    console.log("Starting to fetch events for provider IDs:", providerIds, "on date:", date);
    
    try {
      const providerPromises = providerIds.map(async (providerId) => {
        console.log(`Fetching events for provider ID: ${providerId}`);
        
        const payload = {
          provider_id: parseInt(providerId),
          start_date: format(date, 'yyyy-MM-dd'),
          end_date: format(date, 'yyyy-MM-dd')
        };
        
        console.log("Payload for provider ID:", providerId, payload);
        
        const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get-provider-events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        let parsedData;
        try {
          parsedData = JSON.parse(data);
        } catch (parseError) {
          console.error('Error parsing data:', parseError);
          parsedData = [];
        }
  
        return parsedData.map(event => ({
          ...event,
          providerId: providerId,
          providerName: getProviders.find(p => p.id === providerId)?.name || 'Unknown Provider'
        }));
      });
  
      const providerEvents = await Promise.all(providerPromises);
      const allEvents = providerEvents.flat();
  
      const validEvents = allEvents.filter(event => {
        const startTime = parseDate(event.start_time);
        const endTime = parseDate(event.end_time);
        if (!startTime || !endTime) {
          console.error(`Invalid date for event: ${event.event_id}`);
          return false;
        }
        return true;
      });
  
      console.log("Total valid events fetched:", validEvents.length);
      setEvents(validEvents);
  
    } catch (error) {
      console.error('Error fetching provider events:', error);
      setEvents([]);
    }
  };

  const handleProvidersChange = (providerIds) => {
    console.log("Provider IDS received from Calender:", providerIds);
    setSelectedProviders(providerIds);
    fetchProviderEvents(providerIds);
  }

  const fetchPatientInfo = useCallback(async (patientId) => {
    if (patientId && patientId !== 'null') {
      console.log(`Fetching patient info for ID: ${patientId}`);
      try {
        const response = await axios.get('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/find_patient', {
          headers: {
            PatientID: patientId,
            'Content-Type': 'application/json'
          }
        });
        
        console.log('Raw response data:', response.data);
  
        if (response.data && response.data.body) {
          // Parse the JSON string in the body
          const parsedBody = JSON.parse(response.data.body);
          console.log('Parsed body:', parsedBody);
  
          if (Array.isArray(parsedBody) && parsedBody.length > 0 && parsedBody.length < 2) {
            const patientData = parsedBody[0];
            if (patientData.FirstName && patientData.LastName) {
              console.log(`Patient info received for ID ${patientId}: ${patientData.FirstName} ${patientData.LastName}`);
              return {
                firstName: patientData.FirstName,
                lastName: patientData.LastName
              };
            } else {
              console.log(`Incomplete patient data received for ID ${patientId}`);
            }
          } else if (Array.isArray(parsedBody) && parsedBody.length > 1) {
            const patientData = parsedBody[1];
            if (patientData.FirstName && patientData.LastName) {
              console.log(`Patient info received for ID ${patientId}: ${patientData.FirstName} ${patientData.LastName}`);
              return {
                firstName: patientData.FirstName,
                lastName: patientData.LastName
              };
            } else {
              console.log(`Incomplete patient data received for ID ${patientId}`);
            }
          } else {
            console.log(`No patient data found in the response for ID ${patientId}`);
          }
        } else {
          console.log(`Unexpected response structure for ID ${patientId}`);
        }
      } catch (error) {
        console.error('Error fetching patient info:', error);
        // Log the full error response for debugging
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    } else {
      console.log(`Invalid patient ID: ${patientId}`);
    }
    return null;
  }, []);

  const getEventKey = (event) => {
    const serviceDate = event.start_time.split('T')[0];
    return `${event.event_id}-${event.event_schedule_id}-${serviceDate}`;
  };

  const fetchNotes = useCallback(async (event) => {
    const dateOfService = event.start_time.split('T')[0];
    let noteSubtype;
    
    if (event.event_name === "ST Appointment") {
      noteSubtype = "ST Daily Session Note";
    } else if (event.event_name === "OT Appointment") {
      noteSubtype = "OT Daily Session Note";
    } else {
      return; // Don't fetch notes for other event types
    }

    const url = new URL('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_notes');
    url.searchParams.append('event_id', event.event_id);
    url.searchParams.append('event_sch_id', event.event_schedule_id);
    url.searchParams.append('note_type', 'Daily Session');
    url.searchParams.append('note_subtype', noteSubtype);
    url.searchParams.append('date_of_service', dateOfService);


    try {
      const response = await fetch(url);
      
      if (!response.ok) {
        console.error('API returned an error:', response.status, response.statusText);
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error(`API request failed: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      const notes = Array.isArray(data.body) ? data.body : JSON.parse(data.body);
      const eventKey = getEventKey(event);
      setEventNotes(prevNotes => ({
        ...prevNotes,
        [eventKey]: notes
      }));
      console.log('API response:', JSON.stringify(data, null, 2));

     /*  if (notes.length > 0) {
        navigate('/viewnote/' + notes[0].id, { state: { note: notes[0], patient: { PatientID: event.patient_id } } });
      } else {
        console.log('No notes found for this event');
        // You might want to navigate to a create note page here instead
      } */
    } catch (error) {
      console.error('Error fetching notes:', error);
      // You might want to show an error message to the user here
    }
  }, []);

  const fetchPatients = async () => {
    try {
      const response = await axios.get('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/listpatient');
      
      // Check if the response has data
      if (response.data) {
        // If the response is an array, you can set it directly
        if (Array.isArray(response.data)) {
          setPatients(response.data);
        } else {
          // If the response is not an array, you may need to parse it
          const patientsData = JSON.parse(response.data.body);
          setPatients(patientsData);
        }
      } else {
        console.error('API response has no data');
      }
    } catch (error) {
      console.error('Error fetching patients:', error);
    }
  };

  const handleNoteAction = (event) => {
    const eventKey = getEventKey(event);
    const notes = eventNotes[eventKey];
    if (notes && notes.length > 0) {
      navigate('/viewnote/' + notes[0].id, { state: { note: notes[0], patient: { PatientID: event.pt_id } } });
    } else {
      navigate('/createnote', { 
        state: { 
          noteSection: 'Daily Session', 
          patientIID: event.pt_id,
          event: event,
          provider: event.providerId,
          eventName: event.event_name
        } 
      });
    }
  };

  const handleCalendarDateChange = (newDate) => {
    console.log("Calendar date changed to:", newDate);
    setSelectedCalendarDate(newDate);
  
    // Always fetch events using current selectedProviders
    if (selectedProviders.length === 0) {
      // If no providers are selected, check for the logged-in user's provider
      const matchedProvider = getProviders.find(provider => provider.name.toLowerCase() === userName.toLowerCase());
      if (matchedProvider) {
        fetchProviderEvents([matchedProvider.id], newDate);
      }
    } else {
      fetchProviderEvents(selectedProviders, newDate);
    }
  };

  const parseDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date;
  };

  const sortEventsByDate = (events) => {
    return events.sort((a, b) => {
      const dateA = parseDate(a.start_time);
      const dateB = parseDate(b.start_time);
      if (!dateA || !dateB) return 0; // Handle invalid dates
      return dateA - dateB;
    });
  };
  
  const getEventStatus = (event) => {
    if (event.ev_status === 'A') return 'Scheduled';
    if (event.ev_status === 'Y') return 'Confirmed';
    if (event.ev_status === 'C') return event.cancel_reason || 'Canceled';
    if (event.ev_status === 'N') return 'No Show';
    return 'Scheduled'; // Default value
  };
  
  const getEventClass = (event) => {
    return event.ev_status === 'C' || event.ev_status === 'N'
      ? 'bg-red-50' 
      : 'bg-blue-50';
  };

  const changeEventStatus = async (uniqueEventId, excepDate, cancelReason, excStatus) => {
    const [eventId, eventSchId, formattedDate] = uniqueEventId.split('-');
    
    const payload = {
      event_id: eventId,
      event_schedule_id: eventSchId,
      excep_date: excepDate,
      cancel_reason: cancelReason,
      exc_status: excStatus,
    };
  
    try {
      const response = await fetch(
        'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/create_exception_event',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('create_exception_event response:', data);
  
      const updatedEvent = {
        event_id: parseInt(eventId),
        event_schedule_id: parseInt(eventSchId),
        ev_status: excStatus,
        cancel_reason: cancelReason,
        start_time: excepDate
      };
  
      console.log('Returning updated event:', updatedEvent);
      return updatedEvent;
  
    } catch (error) {
      console.error('Error updating event status:', error);
      throw error;
    }
  };

  const handleProviderChange = (newProviderId) => {
    setSelectedProvId(newProviderId);
    const selectedProvider = getProviders.find(p => p.id === newProviderId);
    setProvider(selectedProvider ? selectedProvider.name : '');
    handleProviderOrDateChange(newProviderId, selectedCalendarDate);
  };

  const handleProviderOrDateChange = (newProviderId, newDate) => {
    const formattedDate = format(newDate || selectedCalendarDate, 'yyyy-MM-dd');
    const provId = newProviderId;
  
    if (provId) {
      const payload = {
        provider_id: parseInt(provId),
        start_date: formattedDate,
        end_date: formattedDate
      };
      console.log("Payload: ", payload);
      fetchEvents(payload);
    } else {
      setEvents([]);
    }
  };

  const handleStatusChange = useCallback(async (e, uniqueEventId, startTime) => {
    const newStatus = e.target.value;
    console.log('New status selected:', newStatus, 'for event on', format(new Date(startTime), 'yyyy-MM-dd'));
  
    let excStatus;
    let cancelReason = '';
    switch (newStatus) {
      case 'Canceled by Patient':
      case 'Canceled by Provider':
        excStatus = 'C';
        cancelReason = newStatus;
        break;
      case 'Scheduled':
        excStatus = 'A';
        break;
      case 'No Show':
        excStatus = 'N';
        break;
      case 'Confirmed':
        excStatus = 'Y';
        break;
      default:
        excStatus = 'A'; // Default to Scheduled
    }
  
    const excepDate = format(new Date(startTime), 'yyyy-MM-dd');
  
    // Optimistic update
    setEvents(prevEvents => {
      const updatedEvents = prevEvents.map(evt => {
        if (evt && `${evt.event_id}-${evt.event_schedule_id}-${format(new Date(evt.start_time), 'yyyy-MM-dd')}` === uniqueEventId) {
          return { ...evt, ev_status: excStatus, cancel_reason: cancelReason };
        }
        return evt;
      });
      console.log('Updated events after optimistic update:', updatedEvents);
      return updatedEvents;
    });
  
    try {
      const updatedEvent = await changeEventStatus(uniqueEventId, excepDate, cancelReason, excStatus);
      console.log('Updated event from server:', updatedEvent);
  
      // Confirm update with server response
      setEvents(prevEvents => {
        const finalUpdatedEvents = prevEvents.map(evt => {
          if (evt && `${evt.event_id}-${evt.event_schedule_id}-${format(new Date(evt.start_time), 'yyyy-MM-dd')}` === uniqueEventId) {
            // Preserve all original properties and only update status-related fields
            return { 
              ...evt, 
              ev_status: updatedEvent.ev_status, 
              cancel_reason: updatedEvent.cancel_reason 
            };
          }
          return evt;
        });
        console.log('Final updated events:', finalUpdatedEvents);
        return finalUpdatedEvents;
      });
  
    } catch (error) {
      console.error('Failed to update event status:', error);
      // Revert on error
      setEvents(prevEvents => {
        const revertedEvents = prevEvents.map(evt => {
          if (evt && `${evt.event_id}-${evt.event_schedule_id}-${format(new Date(evt.start_time), 'yyyy-MM-dd')}` === uniqueEventId) {
            return { ...evt, ev_status: evt.originalStatus, cancel_reason: evt.originalCancelReason };
          }
          return evt;
        });
        console.log('Reverted events after error:', revertedEvents);
        return revertedEvents;
      });
    }
  }, [changeEventStatus]);

  /* const matchUserNameToProvider = useCallback(() => {
    const matchedProvider = getProviders.find(provider => provider.name.toLowerCase() === userName.toLowerCase());
    if (matchedProvider) {
      setProvider(matchedProvider.name);
      handleProviderChange(matchedProvider.id);
    } else {
      setEvents([]);
      console.log("Please Add Yourself To The System!");
    }
  }, [getProviders, userName, setProvider, handleProviderChange]); */

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
  
    try {
      const params = new URLSearchParams(); 
  
      const response = await axios.get(`https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_providers?${params.toString()}`);
  
      let providersArray = [];
  
      if (typeof response.data === 'string') {
        // Custom parsing for the specific string format
        const match = response.data.match(/body=(\[.*?\])/s);
        if (match && match[1]) {
          try {
            const bodyContent = match[1].replace(/'/g, '"'); // Replace single quotes with double quotes
            const parsedBody = JSON.parse(bodyContent);
            if (Array.isArray(parsedBody)) {
              providersArray = parsedBody.map(provider => ({
                id: provider.pr_id,
                name: provider.pr_name
              }));
            }
          } catch (parseError) {
            console.error('Error parsing body content:', parseError);
          }
        } else {
          console.error('Unable to extract body content from response');
        }
      } else if (typeof response.data === 'object' && response.data !== null) {
        // Handle case where response.data is already an object
        if (response.data.body && Array.isArray(response.data.body)) {
          providersArray = response.data.body.map(provider => provider.pr_name);
        } else {
          console.error('Unexpected data structure:', response.data);
        }
      } else {
        console.error('Unexpected response type:', typeof response.data);
      }
  
      setGetProviders(providersArray);
    } catch (error) {
      console.error('Error calling Lambda function:', error);
      setGetProviders([]);
    }
  };

  const fetchEvents = async (payload) => {
    console.log("fetchEvents called with payload:", payload);
    try {
      const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get-provider-events', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Lambda function response:', data);
      
      // Parse JSON string (data) into an array
      let parsedData;
      try {
        parsedData = JSON.parse(data);
      } catch (parseError) {
        console.error('Error parsing data:'. parseError);
        parsedData = [];
      }

      const validEvents = parsedData.filter(event => {
        const startTime = parseDate(event.start_time);
        const endTime = parseDate(event.end_time);
        if (!startTime || !endTime) {
          console.error(`Invalid date for event: ${event.event_id}`);
          return false;
        }
        return true;
      });
  
      setEvents(validEvents);
    } catch (error) {
      console.error('Error calling Lambda function: ', error);
      setEvents([]);
    }
  };

  const getLocationAddress = (locationCode) => {
    switch (locationCode) {
      case 8:
        return '8 Johnson St';
      case 225:
        return '225 Richmond Hill Rd';
      case 445:
        return '445 Forest Ave';
      default:
        return 'Unknown Location';
    }
  };

  const handleStartDateChange = (e) => {
    setStartDate(new Date(e.target.value));
  };
  const handleEndDateChange = (e) => {
    setEndDate(new Date(e.target.value));
  };

  const handleEventClick = (event) => {
    const startDate = new Date(event.start_time);
    const formattedDate = format(startDate, 'yyyy-MM-dd');
    const eventId = `${event.event_id}-${event.event_schedule_id}-${formattedDate}`;
    const currentPage = "HomePage";
    navigate(`/modifyevent/${eventId}`, {
      state: { event, page: currentPage } 
    });
  };

  useEffect(() => {
    handleSubmit();
    //callLambdaFunction();
  }, []);

  useEffect(() => {
    if (!isLoading && getProviders.length > 0 && userName) {
      console.log("Checking for provider match with username:", userName);
      const matchedProvider = getProviders.find(provider => provider.name.toLowerCase() === userName.toLowerCase());
      console.log("Matched provider:", matchedProvider);
      
      setUserMatchesProvider(!!matchedProvider);
      
      if (matchedProvider && !selectedProvID) {
        setProvider(matchedProvider.name);
        setSelectedProvId(matchedProvider.id);
        // Add the matched provider to selectedProviders if not already present
        setSelectedProviders(prev => {
          if (!prev.includes(matchedProvider.id)) {
            return [matchedProvider.id];
          }
          return prev;
        });
        fetchProviderEvents([matchedProvider.id], selectedCalendarDate);
      } else if (!matchedProvider) {
        setEvents([]);
        console.log("Please Add Yourself To The System!");
      }
    }
  }, [userName, getProviders, selectedCalendarDate, isLoading, selectedProvID]);

  useEffect(() => {
    const fetchPatientInfoForEvents = async () => {
      console.log('Fetching patient info for events');
      const patientInfoPromises = events.map(async (event) => {
        if (event.pt_id && event.pt_id !== 'null') {
          const info = await fetchPatientInfo(event.pt_id);
          return { [event.pt_id]: info };
        }
        return null;
      });
  
      const patientInfoResults = await Promise.all(patientInfoPromises);
      const newPatientInfo = patientInfoResults.reduce((acc, info) => {
        if (info) {
          return { ...acc, ...info };
        }
        return acc;
      }, {});
  
      console.log('Setting patient info:', newPatientInfo);
      setPatientInfo(newPatientInfo);
    };
  
    fetchPatientInfoForEvents();
  }, [events, fetchPatientInfo]);

  useEffect(() => {
    if (userName) {
      setIsLoading(false);
    }
  }, [userName]);

  useEffect(() => {
    events.forEach(event => {
      if (event && event.event_name && (event.event_name === "ST Appointment" || event.event_name === "OT Appointment")) {
        fetchNotes(event);
      }
    });
  }, [events, fetchNotes]);

  useEffect(() => {
    const colors = [
      'bg-blue-100 border-blue-500',
      'bg-emerald-100 border-emerald-500',
      'bg-purple-100 border-purple-500',
      'bg-amber-100 border-amber-500',
      'bg-rose-100 border-rose-500',
      'bg-cyan-100 border-cyan-500'
    ];
    
    const newProviderColors = {};
    selectedProviders.forEach((provider, index) => {
      newProviderColors[provider] = colors[index % colors.length];
    });
    
    setProviderColors(newProviderColors);
  }, [selectedProviders]);

  useEffect(() => {
    const fetchAlerts = async () => {
      if (!userName) return;
    
      try {
        const response = await axios.post('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_alerts', {
          user_name: userName
        });
    
        // Parse the nested body string into JSON
        const alertsData = JSON.parse(response.data.body);
        
        // Set the parsed alerts directly
        setAlerts(alertsData);
        console.log("Alerts:", alertsData);
        setError(null); // Clear any existing errors
        
      } catch (error) {
        console.error('Error fetching alerts:', error);
        setError('Failed to load alerts');
      } finally {
        setLoading(false);
      }
    };
  
    fetchAlerts();
  }, [userName]);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex flex-1 mt-12">
        <div className="w-1/3 -ml-12">
          <Calendar onDateSelect={handleCalendarDateChange} onProvidersSelect={handleProvidersChange} />
        </div>
        <div className="flex flex-col w-2/3">
          <div className="flex flex-1">
          <div className="w-8/12 mt-8">
          <div className="bg-gray-200 rounded-xl p-2 h-[calc(120vh-5rem)]">
            {/* {userName && getProviders.some(provider => provider.name.toLowerCase() === userName.toLowerCase()) ? (               */}
              <div className="bg-white shadow-lg rounded-lg h-full p-6 flex flex-col">
                {!userMatchesProvider && !selectedProvID  && selectedProviders.length === 0 ? (
                  <p className="text-red-500 font-bold p-4 text-center">Please Refresh <span className="text-blue-800 underline">OR</span> Add Yourself To The System!</p>
                ) : events.length === 0 ? (
                  <p className="text-gray-500 italic text-center">No appointments scheduled for the selected provider and date.</p>
                ) : (
                  <ul className="space-y-4 overflow-y-auto">
                    {sortEventsByDateTime(events).map((event, index) => {
                      console.log("rendering event: ", event);
                      const startTime = parseDate(event.start_time);
                      const endTime = parseDate(event.end_time);

                      if (!startTime || !endTime) {
                        console.error(`Invalid date for event: ${event.event_id}`, event);
                        return null;
                      }

                      const formattedDate = format(startTime, 'yyyy-MM-dd');
                      const uniqueEventId = `${event.event_id}-${event.event_schedule_id}-${formattedDate}`;
                      const eventStatus = getEventStatus(event);
                      const eventClass = getEventClass(event);
                      const eventKey = getEventKey(event);
                      const providerColor = providerColors[event.providerName] || 'bg-gray-100 border-gray-500';

                      // Determine status-based styling
                      /* let statusStyle = '';
                      switch(event.ev_status) {
                        case 'C':
                          statusStyle = 'bg-red-50 border-red-300';
                          break;
                        case 'N':
                          statusStyle = 'bg-orange-50 border-orange-300';
                          break;
                        default:
                          statusStyle = providerColor;
                      } */

                      return (
                        <li key={uniqueEventId} className="flex items-start space-x-4 p-4 bg-stone-200 shadow-lg hover:bg-gray-200 rounded-lg transition duration-150 ease-in-out">
                          <div className="flex-shrink-0 w-16 text-center">
                            <p className="text-2xl font-semibold text-gray-900">{format(startTime, 'dd')}</p>
                            <p className="text-sm font-medium text-gray-500">{format(startTime, 'MMM')}</p>
                          </div>
                          <div className="flex-grow">
                            <div className="flex justify-between items-start">
                            <div>
                              {/* Provider name badge */}
                              <div className="mb-2">
                                <span className={`inline-block px-2 py-1 text-xs font-semibold rounded-full ${providerColor.replace('bg-', 'text-').replace('-100', '-700')}`}>
                                  {event.providerName}
                                </span>
                              </div>
                              {patientInfo[event.pt_id] ? (
                                <>
                                  <div className="mt-1 flex items-center text-sm text-black">
                                    <UserIcon className="font-bold h-4 w-4 mr-1" />
                                    <span className="text-lg font-semibold text-gray-900">{`${patientInfo[event.pt_id].firstName} ${patientInfo[event.pt_id].lastName}`}</span>
                                  </div>
                                  <div>
                                    <div className="mt-1 flex items-center text-sm text-black">
                                      <CommandLineIcon className="h-4 w-4 mr-1" />
                                      <span className="text-gray-900">{event.event_name}</span>
                                    </div>
                                    <div className="mt-1 flex items-center text-sm text-black">
                                      <ClockIcon className="h-4 w-4 mr-1" />
                                      <span>{format(startTime, 'h:mm a')} - {format(endTime, 'h:mm a')}</span>
                                    </div>
                                    <div className="mt-1 flex items-center text-sm text-black">
                                      <MapPinIcon className="h-4 w-4 mr-1" />
                                      <span>{getLocationAddress(event.ev_location)}</span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div>
                                  <div className="flex items-center text-sm text-black">
                                    <CommandLineIcon className="font-bold h-5 w-5 mr-1" />
                                    <h3 className="text-lg font-semibold text-gray-900">{event.event_name}</h3>
                                  </div>
                                  <div className="mt-1 flex items-center text-sm text-black">
                                    <ClockIcon className="h-4 w-4 mr-1" />
                                    <span>{format(startTime, 'h:mm a')} - {format(endTime, 'h:mm a')}</span>
                                  </div>
                                  <div className="mt-1 flex items-center text-sm text-black">
                                    <MapPinIcon className="h-4 w-4 mr-1" />
                                    <span>{getLocationAddress(event.ev_location)}</span>
                                  </div>
                                </div>
                              )}
                            </div>
                              <div className="flex flex-col items-end">
                                <select 
                                  className="text-sm border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 pr-8 mb-2"
                                  onChange={(e) => handleStatusChange(e, uniqueEventId, startTime)} 
                                  value={eventStatus}
                                >
                                  <option value="Confirmed">Confirmed</option>
                                  <option value="Scheduled">Scheduled</option>
                                  <option value="Canceled by Patient">Canceled by Patient</option>
                                  <option value="Canceled by Provider">Canceled by Provider</option>
                                  <option value="No Show">No Show</option> 
                                </select>
                                <div className="flex space-x-2 mb-4">
                                  {(event.event_name === "ST Appointment" || event.event_name === "OT Appointment") && (
                                    <button 
                                      onClick={() => handleNoteAction(event)}
                                      className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-sm"
                                    >
                                      {eventNotes[eventKey] && eventNotes[eventKey].length > 0 ? 'Go To Note' : 'Create Note'}
                                    </button>
                                  )}
                                  <button 
                                    onClick={() => handleEventClick(event)}
                                    className="mt-2 bg-teal-500 hover:bg-teal-700 text-white font-bold py-1 px-2 rounded text-sm"
                                  >
                                    Modify Event
                                  </button>
                                </div>
                                <div>
                                {(event.charged === true) && (
                                  <span className='px-3 py-1 text-sm font-medium rounded-full border bg-blue-100 text-black border-blue-300'>
                                    Charged
                                  </span>
                                )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
          </div>
        </div>
        <div className="w-4/12 pl-3 mt-8 -mr-6 ">
          <div className="space-y-4">
          <div className="rounded-lg">
            <h2 className="font-bold text-blue-700 text-lg mb-1">Recent Alerts</h2>
            <div className="space-y-1 max-h-[50vh] overflow-y-auto">
              {alerts
                .filter(alert => alert.alert_to === userName)
                .sort((a, b) => {
                  const importancePriority = {
                    'Urgent': 0,
                    'High': 1,
                    'Medium': 2,
                    'Low': 3
                  };
                  
                  if (importancePriority[a.importance] !== importancePriority[b.importance]) {
                    return importancePriority[a.importance] - importancePriority[b.importance];
                  }
                  return new Date(a.due_at) - new Date(b.due_at);
                })
                .slice(0, 3)
                .map((alert) => {
                  const createdDate = new Date(alert.created_at);
                  const dueDate = new Date(alert.due_at);
                  const timeAgo = Math.floor((new Date() - createdDate) / (1000 * 60 * 60));
                  
                  let importanceColor = {
                    'Urgent': 'bg-red-100 text-red-800',
                    'High': 'bg-orange-100 text-orange-800',
                    'Medium': 'bg-blue-100 text-blue-800',
                    'Low': 'bg-gray-100 text-gray-800'
                  };

                  return (
                    <div key={alert.alert_id} className="bg-gray-50 p-2 rounded-md border border-gray-200">
                      <div className="flex justify-between items-center mb-2">
                        <span className="text-sm font-semibold text-gray-600">{alert.alert_type}</span>
                        <span className="text-xs text-gray-400">Created {timeAgo}h ago</span>
                      </div>
                      <p className="text-sm text-gray-700 truncate" title={alert.description}>{alert.description}</p>
                      <div className="mt-2 flex justify-between items-center">
                        <span className="text-xs text-gray-500">Due: {dueDate.toLocaleDateString()}</span>
                        <span className={`inline-block ${importanceColor[alert.importance]} text-xs px-2 py-1 rounded`}>
                          {alert.importance}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="w-11/12 ml-4 mx-auto mt-4 p-4 bg-white rounded-lg shadow-lg border border-gray-200">
            <PieChart events={events} />
          </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    </div>
  );
};

export default HomePage;

