import React, { useState, useEffect, useCallback, useRef } from 'react';
import { format, addDays, subDays } from 'date-fns';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/solid';
import { Menu, MenuItem, MenuButton, MenuItems, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Header from "./Header";
import CreateEventModal from './CreateEventModal';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [providerName, setProviderName] = useState('');
  const [location, setLocation] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [getProviders, setGetProviders] = useState([]);
  const [patientInfo, setPatientInfo] = useState({});

  const [provider, setProvider] = useState('');
  const [events, setEvents] = useState([]);
  const [selectedProvID, setSelectedProvId] = useState(null);

  const [provider2, setProvider2] = useState('');
  const [events2, setEvents2] = useState([]);
  const [selectedProvID2, setSelectedProvId2] = useState(null);

  // Patient info fetching function
  const fetchPatientInfo = useCallback(async (patientId) => {
    if (patientId && patientId !== 'null') {
      try {
        const response = await axios.get('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/find_patient', {
          headers: {
            PatientID: patientId,
            'Content-Type': 'application/json'
          }
        });
        
        if (response.data && response.data.body) {
          const parsedBody = JSON.parse(response.data.body);
          if (Array.isArray(parsedBody) && parsedBody.length > 0) {
            const patientData = parsedBody[0];
            if (patientData.FirstName && patientData.LastName) {
              return {
                firstName: patientData.FirstName,
                lastName: patientData.LastName
              };
            }
          }
        }
      } catch (error) {
        console.error('Error fetching patient info:', error);
      }
    }
    return null;
  }, []);

  // Fetch patient info for all events
  useEffect(() => {
    const fetchPatientInfoForEvents = async () => {
      const allEvents = [...events, ...events2];
      const uniquePatientIds = [...new Set(allEvents.map(event => event.pt_id))];
      
      const patientInfoPromises = uniquePatientIds.map(async (patientId) => {
        const info = await fetchPatientInfo(patientId);
        return { [patientId]: info };
      });

      const patientInfoResults = await Promise.all(patientInfoPromises);
      const newPatientInfo = patientInfoResults.reduce((acc, info) => {
        return { ...acc, ...info };
      }, {});

      setPatientInfo(newPatientInfo);
    };

    fetchPatientInfoForEvents();
  }, [events, events2, fetchPatientInfo]);

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
  
    try {
      const params = new URLSearchParams();
      if (location) params.append('location', location);
      if (specialty) params.append('specialty', specialty);
      if (providerName) params.append('name', providerName + '%');
  
      const response = await axios.get(`https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_providers?${params.toString()}`);
      let providersArray = [];
  
      if (typeof response.data === 'string') {
        const match = response.data.match(/body=(\[.*?\])/s);
        if (match && match[1]) {
          try {
            const bodyContent = match[1].replace(/'/g, '"');
            const parsedBody = JSON.parse(bodyContent);
            if (Array.isArray(parsedBody)) {
              providersArray = parsedBody.map(provider => ({
                id: provider.pr_id,
                name: provider.pr_name
              }));
            }
          } catch (parseError) {
            console.error('Error parsing body content:', parseError);
          }
        }
      } else if (typeof response.data === 'object' && response.data !== null) {
        if (response.data.body && Array.isArray(response.data.body)) {
          providersArray = response.data.body.map(provider => provider.pr_name);
        }
      }
  
      setGetProviders(providersArray);
    } catch (error) {
      console.error('Error calling Lambda function:', error);
      setGetProviders([]);
    }
  };

  const updateCurrentDate = (date) => {
    setCurrentDate(date);
    setSelectedDate(date);
    handleProviderOrDateChange(selectedProvID, date);
    handleProviderOrDateChange2(selectedProvID2, date);
  };

  const goForward = () => {
    const newDate = addDays(currentDate, 1);
    updateCurrentDate(newDate);
  };

  const goToday = () => {
    const newDate = new Date();
    updateCurrentDate(newDate);
  };

  const goBackward = () => {
    const newDate = subDays(currentDate, 1);
    updateCurrentDate(newDate);
  };

  const fetchEvents = async (payload, setEventsFunction) => {
    try {
      const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get-provider-events', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      const parsedData = JSON.parse(data);
      setEventsFunction(parsedData);
    } catch (error) {
      console.error('Error fetching events:', error);
      setEventsFunction([]);
    }
  };

  const handleProviderOrDateChange = (newProviderId, newDate) => {
    const formattedDate = format(newDate || currentDate, 'yyyy-MM-dd');
    const provId = newProviderId || selectedProvID;

    if (provId) {
      const payload = {
        provider_id: parseInt(provId),
        start_date: formattedDate,
        end_date: formattedDate
      };
      fetchEvents(payload, setEvents);
    } else {
      setEvents([]);
    }
  };

  const handleProviderOrDateChange2 = (newProviderId, newDate) => {
    const formattedDate = format(newDate || currentDate, 'yyyy-MM-dd');
    const provId = newProviderId || selectedProvID2;

    if (provId) {
      const payload = {
        provider_id: parseInt(provId),
        start_date: formattedDate,
        end_date: formattedDate
      };
      fetchEvents(payload, setEvents2);
    } else {
      setEvents2([]);
    }
  };

  useEffect(() => {
    handleSubmit();
    const currentMinute = new Date().getHours() * 60;
    container.current.scrollTop =
      ((container.current.scrollHeight -
        containerNav.current.offsetHeight -
        containerOffset.current.offsetHeight) *
        currentMinute) /
      1440;
  }, []);

  // Generate time slots
  const timeSlots = Array.from({ length: 15 }, (_, i) => {
    const hour = i + 7;
    return format(new Date().setHours(hour, 0), 'ha').toLowerCase();
  });

  const renderEvent = (event, patientInfo) => {
    const startTime = new Date(event.start_time);
    const endTime = new Date(event.end_time);
    const startHour = startTime.getHours();
    const startMinutes = startTime.getMinutes();
    const duration = (endTime - startTime) / (1000 * 60);
    const gridRowStart = (startHour - 6) * 2 + Math.floor(startMinutes / 30) + 2;
    const gridRowSpan = Math.ceil(duration / 30);
    const patient = patientInfo[event.pt_id] || {};
  
    const getStatusColor = (status) => {
      switch (status) {
        case 'A': return 'bg-green-100 text-green-800';
        case 'C': return 'bg-red-100 text-red-800';
        default: return 'bg-gray-100 text-gray-800';
      }
    };
  
    return (
      <li
        key={event.event_id}
        className="relative mt-px flex"
        style={{gridRow: `${gridRowStart} / span ${gridRowSpan}`}}
      >
        <div className="group absolute inset-1 flex flex-col overflow-hidden rounded-lg bg-white shadow-sm border border-blue-100 hover:shadow-md transition-all duration-200">
          {/* Compact layout with all info in header */}
          <div className="bg-blue-50 p-1.5 flex flex-col min-h-0">
            <div className="flex items-center justify-between">
              <h3 className="font-medium text-sm text-blue-900 truncate">
                {patient.firstName} {patient.lastName || `Patient ${event.pt_id}`}
              </h3>
              <span className={`px-1.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(event.ev_status)}`}>
                {event.ev_status}
              </span>
            </div>
            <div className="text-xs text-gray-600 flex items-center justify-between">
              <span>{event.event_name}</span>
              <span>
                {startTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', timeZone: 'America/New_York' })}
              </span>
            </div>
          </div>
        </div>
      </li>
    );
  };

  return (
    <>
      {/* Keep your existing header and controls */}
      <div className="z-30 sticky">
        <Header />
      </div>
      {/* Rest of your existing JSX */}
      <div className="flex items-center justify-center pt-24 px-6">
        <div>
          <h1 className="text-lg font-semibold leading-6 text-gray-900">
            <time className="hidden sm:inline">
              {format(currentDate, 'MMMM d, yyyy')}
            </time>
          </h1>
          <p className="mt-1 text-sm text-gray-500">{format(currentDate, 'EEEE')}</p>
        </div>
        <div className="flex items-center">
          <div className="flex items-center rounded-md shadow-sm md:items-stretch ml-12">
            <button
              type="button"
              className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
              onClick={goBackward}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              onClick={goToday}
              className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
            >
              Today
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              type="button"
              onClick={goForward}
              className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden md:ml-4 md:flex md:items-center">
            <Menu as="div" className="relative">
              <MenuButton
                type="button"
                className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                Two Provider View
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </MenuButton>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems className="focus:outline-none absolute right-0 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="py-1">
                    <MenuItem>
                        <Link to="/calendar" className='text-gray-700 block px-4 py-2 text-sm'>
                          Day View
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/weekview" className='text-gray-700 block px-4 py-2 text-sm'>
                          Week View
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/twoprovview" className='text-gray-700 block px-4 py-2 text-sm'>
                          Two Provider View
                        </Link>
                    </MenuItem>
                  </div>
                </MenuItems>
              </Transition>
            </Menu>
            <div className="ml-6 h-6 w-px bg-gray-300" />
            <button
              type="button"
              onClick = {() => setShowCreateEvent(true)}
              className="focus:outline-none ml-6 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Add Event
            </button>
            <CreateEventModal
              isOpen={showCreateEvent}
              onClose={() => setShowCreateEvent(false)}
            />
          </div>
        </div>
        </div>
        <div className="flex items-center justify-around py-6 px-6 border-b border-gray-200">
          <div className="flex items-center">
            <label htmlFor="provider" className="block mr-4 text-sm font-medium text-gray-900 dark:text-black">Provider #1</label>
            <div class="">
            <select 
              value={provider} 
              onChange={(e) => {
                const selectedProvider = getProviders.find(p => p.name === e.target.value);
                setProvider(e.target.value);
                const newProviderId = selectedProvider ? selectedProvider.id : null;
                setSelectedProvId(newProviderId);
                handleProviderOrDateChange(newProviderId, currentDate);
              }} 
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
              id="grid-state"
            >
              <option>None</option>
              {getProviders.length > 0 ? (
                [...getProviders]
                .map(provider => ({
                  ...provider,
                  formattedName: provider.name.split(' ').reverse().join(', ')
                }))
                .sort((a, b) => a.formattedName.localeCompare(b.formattedName))
                .map((provider) => (
                  <option key={provider.id} value={provider.name}>
                    {provider.formattedName}
                  </option>
                ))
              ) : (
                <option>No providers available</option>
              )}
            </select>
            </div>
          </div>
          <div className="flex items-center">
            <label htmlFor="provider" className="block mr-4 text-sm font-medium text-gray-900 dark:text-black">Provider #2</label>
            <div class="">
            <select 
              value={provider2} 
              onChange={(e) => {
                const selectedProvider = getProviders.find(p => p.name === e.target.value);
                setProvider2(e.target.value);
                const newProviderId = selectedProvider ? selectedProvider.id : null;
                setSelectedProvId2(newProviderId);
                handleProviderOrDateChange2(newProviderId, currentDate);
              }} 
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
              id="grid-state"
            >
              <option>None</option>
              {getProviders.length > 0 ? (
                [...getProviders]
                .map(provider => ({
                  ...provider,
                  formattedName: provider.name.split(' ').reverse().join(', ')
                }))
                .sort((a, b) => a.formattedName.localeCompare(b.formattedName))
                .map((provider) => (
                  <option key={provider.id} value={provider.name}>
                    {provider.formattedName}
                  </option>
                ))
              ) : (
                <option>No providers available</option>
              )}
            </select>
            </div>
          </div>
        </div>
      {/* Update the time grid section */}
      <div className="flex">
        {[
          { events: events, ref: container },
          { events: events2, ref: container }
        ].map((side, index) => (
          <div key={index} className="flex flex-auto overflow-hidden bg-white border-l border-gray-100">
            <div ref={side.ref} className="flex flex-auto flex-col overflow-auto">
              <div ref={containerNav} className="sticky top-0 z-10 bg-white">
                {/* Your existing nav content */}
              </div>
              <div className="flex w-full flex-auto">
                <div className="w-14 flex-none bg-white ring-1 ring-gray-100" />
                <div className="grid flex-auto grid-cols-1 grid-rows-1">
                  {/* Time slots */}
                  <div
                    className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                    style={{ gridTemplateRows: 'repeat(30, minmax(3.5rem, 1fr))' }}
                  >
                    <div ref={containerOffset} className="row-end-1 h-7"></div>
                    {timeSlots.map((time) => (
                      <Fragment key={time}>
                        <div>
                          <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                            {time}
                          </div>
                        </div>
                        <div />
                      </Fragment>
                    ))}
                  </div>

                  {/* Events */}
                  <ol
                    className="col-start-1 col-end-2 row-start-1 grid grid-cols-1"
                    style={{
                      gridTemplateRows: '1.75rem repeat(30, minmax(6rem, 1fr)) auto',
                    }}
                  >
                    {side.events.map((event) => renderEvent(event, patientInfo))}
                  </ol>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}